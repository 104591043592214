// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants
import { BORDER_RADIUS } from "~/constants";

export const StyledButton = styled.div<{ isSaving?: boolean }>`
    ${flexSmart("center center")}
    height: 29px;
    padding: 10px;
    font-size: 17px;
    font-weight: 400;
    margin-right: 10px;
    gap: 5px;

    background-color: ${props => props.theme.colors["primary"].rgba};

    color: ${props => props.theme.colors["text-secondary"].rgba};

    border: none;
    border-radius: ${BORDER_RADIUS}px;

    :hover {
        cursor: ${props => (props.isSaving ? 'not-allowed' : 'pointer')}; // Disable hover cursor
    }

    opacity: ${props => (props.isSaving ? 0.6 : 1)};  // Reduce opacity when saving
    pointer-events: ${props => (props.isSaving ? 'none' : 'auto')}; // Prevent interaction during saving
`;