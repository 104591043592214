// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
// components

export type IProps = {
    doneCallback: () => void;
    callback?: () => void;
};

export const SaveConfirmationModal: React.FC<IProps> = (props) => {

    const {
        doneCallback, callback
    } = props;

    return (
        <SC.StyledModal>

            <FontAwesomeIcon
                className="fa-fw"
                icon={faTriangleExclamation}
                size={"5x"}
                color={"#FFCC00"}
            />

            <SC.TextSection>
                <SC.BoldText>Are you sure you want to save without naming the videoboard?</SC.BoldText>
                <SC.Text>If you change your mind, you can rename it later.</SC.Text>
            </SC.TextSection>

            <SC.ButtonSection>
                <SC.StyledButton cancel={true} onClick={doneCallback}>
                    {"Cancel"}
                </SC.StyledButton>

                <SC.StyledButton onClick={() => {if(callback) callback(); doneCallback();}}>
                    <Fragment>
                        {"Save Videoboard"}
                    </Fragment>
                </SC.StyledButton>
            </SC.ButtonSection>

        </SC.StyledModal>
    )
}

export default SaveConfirmationModal;