// libraries
import * as React from "react";
import { faSquarePlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// styles
import * as SC from "./Styled";
// types & models
import { ATVideoBoard } from "@algo/network-manager/models/v4";
// component
import Snapshot from "~/components/views/snapshot/Snapshot";
import { useLoadCameras } from "../cameras/hooks";

export type IProps = {
    videoboard: ATVideoBoard | undefined,
    pageLayout: any,
    currentPageIndex: number,
    draggedItemId: number | undefined,
    setVideoboard: React.Dispatch<React.SetStateAction<ATVideoBoard | undefined>>
};

export const VideoboardSection: React.FC<IProps> = (props) => {

    const {
        videoboard, pageLayout, currentPageIndex, draggedItemId, setVideoboard
    } = props;

    const cameraStore: any = useLoadCameras();
    const filteredCameras = cameraStore.dataFilteredBySelections;
    
    const [isHovering, setIsHovering] = React.useState<number | null>(null);

    // Handle a drag over
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
        event.preventDefault();
        setIsHovering(index);
    };

     // Handle when drag leaves the target area
    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsHovering(null);
    };

    // Handle when a drop occurs
    const handleDrop = (event: React.DragEvent<HTMLDivElement>, index: number) => {
        event.preventDefault();
        
        if(videoboard){
            const newVideoboard: ATVideoBoard = new ATVideoBoard({
                ...videoboard,
            });
            
            const newCamera = filteredCameras?.find((x: any) => x.id === draggedItemId);
            if(newCamera) newVideoboard.pages[currentPageIndex].items[index].camera = newCamera;
            
            setVideoboard(newVideoboard);
            setIsHovering(null);
        }
    }

    // Handle camera item delete
    const handleDelete = (index: number) => {
        if(videoboard){
            const newVideoboard: ATVideoBoard = new ATVideoBoard({
                ...videoboard,
            });
            
            newVideoboard.pages[currentPageIndex].items[index].camera = null;
            
            setVideoboard(newVideoboard);
        }
    }

    return (
        <SC.VideoboardsWrap>
            <SC.Videoboards rowCount={pageLayout?.rowCount} columnCount={pageLayout?.columnCount}>
                {videoboard?.pages &&
                    videoboard?.pages[currentPageIndex].items.map((videoItem: any, index: any) => {
                        return (
                            <SC.SnapshotWrap key={`${videoboard.id} - ${currentPageIndex} - ${index}`} 
                                onDrop={(event) => handleDrop(event, index)}
                                onDragOver={(event) => handleDragOver(event, index)}
                                onDragLeave={(event) => handleDragLeave(event)}>
                                {(!videoItem.camera) &&
                                    <SC.SnapshotPlaceholder hovering={isHovering === index}>
                                        <FontAwesomeIcon
                                            className="fa-fw"
                                            icon={faSquarePlus}
                                            size={"6x"}
                                            color={"rgba(4, 149, 101, 0.09)"}
                                        />
                                    </SC.SnapshotPlaceholder>
                                }
                                {videoItem.camera &&
                                    <Snapshot
                                        key={videoboard.id}
                                        camera={videoItem.camera}
                                        showPlayButton={false}
                                        showDelete={true}
                                        deleteClickHandler={() => {handleDelete(index)}}
                                    />
                                }
                            </SC.SnapshotWrap>
                        )
                    })}
            </SC.Videoboards>
        </SC.VideoboardsWrap>
    );
};

export default VideoboardSection;
