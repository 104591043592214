// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants
import { BORDER_RADIUS } from "~/constants";

export const StyledButton = styled.div`
    ${flexSmart("center center")}

    width: 160px;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    gap: 5px;

    background-color: ${props => props.theme.colors["primary"].rgba};

    color: ${props => props.theme.colors["text-secondary"].rgba};

    border: none;
    border-radius: ${BORDER_RADIUS}px;

    :hover {
        cursor: pointer;
    }
`;