// libraries
import * as React from "react";
import { nuon } from "@caps-mobile/common-lib";
// types & models
import { IATCamera, IATCameraDto } from "@algo/network-manager/models/v4";
// styles
import * as SC from "./Styled";
// components
import ImgWithDefault from "../image-with-default/ImgWithDefault";
import CameraInfoOverlay from "../camera-info-overlay/CameraInfoOverlay";
// resources
import noPreviewImg from "~/resources/ui/graphics/no-camera-preview-url-provided.png";
import playBtn from "~/resources/ui/graphics/play-button.png";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type IProps = {
    camera: IATCamera | IATCameraDto | undefined | null;
    height?: string;
    width?: string;
    showPlayButton?: boolean;
    showCityOverlay?: boolean;
    editVideoboard?: boolean;
    showDelete?: boolean;
    deleteClickHandler?: () => void;
    clickCallback?: (cameraId: number) => void;
    onDragStart?: (event: React.DragEvent<HTMLDivElement>, cameraId: number | undefined) => void;
};

export const Snapshot: React.FC<IProps> = React.memo((props) => {

    let {  
        height = "100%", width = "100%", editVideoboard, showDelete, deleteClickHandler,
        camera, showPlayButton, showCityOverlay, clickCallback , onDragStart
    } = props;
    
    const imageUrl = camera?.snapshotImageUrl || noPreviewImg;
    const hasPlaybackURL = camera?.playbackUrls?.dash || camera?.playbackUrls?.hls ? true : false;

    const hasClickCallback: boolean = nuon(clickCallback);
    const hasDragCallback: boolean = nuon(onDragStart);

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        if(hasDragCallback && onDragStart) onDragStart(event, camera?.id);
    };

    return (
        <SC.SnapshotWrapper draggable={true} isClickable={(hasClickCallback && hasPlaybackURL) || hasDragCallback} editVideoboard={editVideoboard}
            onClick={() => clickCallback && hasPlaybackURL && clickCallback(camera?.id ?? 0)} onDragStart={(event) => hasDragCallback && handleDragStart(event)}
        >

            <ImgWithDefault 
                src={imageUrl} fallbackSrc={noPreviewImg} 
                width={width} height={height} editVideoboard={editVideoboard} />

            {camera && 
                <CameraInfoOverlay camera={camera} editVideoboard={editVideoboard}
                    showRouteOverlay={true} showCityOverlay={showCityOverlay} />
            }

            { showDelete &&
                <SC.BottomRow>
                    <SC.SettingsWrapper>
                        <SC.Overlay bottom={true} onClick={(event: any) => { 
                            event.stopPropagation();
                            if(deleteClickHandler) deleteClickHandler();  }}>

                            <SC.Primary>
                                <FontAwesomeIcon
                                    className="fa-fw"
                                    icon={faTrash}
                                    size={"xl"}
                                    color={"#FF3B30"}
                                />
                            </SC.Primary>

                        </SC.Overlay>
                    </SC.SettingsWrapper>
                </SC.BottomRow>
            }

            {   showPlayButton && hasPlaybackURL &&
                <SC.FloatingPlayBtn>
                    <ImgWithDefault src={playBtn} width={"52px"} height={"52px"} />
                </SC.FloatingPlayBtn>
            }

        </SC.SnapshotWrapper>
    )
});

export default Snapshot;