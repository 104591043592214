import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faChevronRight, faExternalLink } from "@fortawesome/pro-regular-svg-icons";

declare var __ADMIN_URL__: string;

export type INavLink = {
    id: number;
    name?: string;
    iconComp?: React.ReactElement;
    link?: string;
    route?: string;
};

export type INavSection = {
    id: number;
    title?: string;
    collapse?: boolean;
    links?: INavLink[];
};

export type INavMap = {
    title?: string;
    navSections: INavSection[];
};

export enum ESiteNavRoutes {
    "/",
    "/map",
    "/traffic-reports",
    "/travel-times",
    "/cameras",
    "/trip-planner",
    "/settings",
    "/documents",
    "/external-links",
    "/social-media",
    "/law-enforcement-alerts",
    "/about",
    "/terms-of-use",
    "/privacy-policy",
    "/asap-survey",
    "/rest-area-survey",
    "/comments",
    "/logout"
};

export const getNavMapValue = (routeString: string, navMap: INavMap): number[] => {

    let sectionIndex: number = -1;
    let linkIndex: number = -1;

    // when the routeString is empty or root, return [0,0] for map page
    if (routeString === "" || routeString === "/") return [0,0];

    // search through each section...
    navMap.navSections.forEach(
        (section: INavSection, sectionMapIndex: number) => {
            // and through each of its links...
            section.links && section.links.forEach(
                (link: INavLink, linkMapIndex: number) => {
                    // until a matching route value is found
                    if (link.route === routeString){
                        sectionIndex = sectionMapIndex;
                        linkIndex = linkMapIndex;
                        return [sectionIndex, linkIndex];
                    }
                }
            )
        }
    );

    // if no match was found, then we are at [-1,-1] and should have no selected nav panel link
    return [sectionIndex, linkIndex];
};

export const navPanelMap: INavMap = {
    navSections: [
        {
            id: 0,
            title: "Traveler Information",
            collapse: true,
            links: [
                {
                    id: 0,
                    name: "Map",
                    iconComp: 
                        <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("map")}
                        />,
                    route: "/map"
                },
                {
                    id: 1,
                    name: "Traffic Reports",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("car")}
                        />,
                    route: "/traffic-reports"
                },
                {
                    id: 2,
                    name: "Travel Times",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("gauge-high")}
                        />,
                    route: "/travel-times"
                },
                {
                    id: 3,
                    name: "Cameras",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("video")}
                        />,
                    route: "/cameras"
                },
                {
                    id: 4,
                    name: "Trip Planner",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("route")}
                        />,
                    route: "/trip-planner"
                },
                {
                    id: 5,
                    name: "Settings",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("gear")}
                        />,
                    route: "/settings"
                }
            ]
        },
        {
            id: 1,
            title: "Video",
            collapse: true,
            links: [
                {
                    id: 0,
                    name: "Videoboards",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("presentation-screen")}
                        />,
                    route: "/videoboards"
                },
                {
                    id: 1,
                    name: "Admin",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("screwdriver-wrench")}
                        />,
                    link: __ADMIN_URL__
                }
            ]
        },
        {
            id: 2,
            title: "Resources",
            collapse: true,
            links: [
                {
                    id: 0,
                    name: "Documents",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("folder")}
                        />,
                    route: "/documents"
                },
                {
                    id: 1,
                    name: "External Links",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("link")}
                        />,
                    route: "/external-links"
                },
                {
                    id: 2,
                    name: "Law Enforcement Alerts",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("user-police")}
                        />,
                    route: "/law-enforcement-alerts"
                },
                {
                    id: 3,
                    name: "Personalized Alerts",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("message-dots")}
                        />,
                    link: "https://www.algotrafficpta.com"
                },
                {
                    id: 4,
                    name: "Social Media",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("hashtag")}
                        />,
                    route: "/social-media"
                },
                {
                    id: 5,
                    name: "About",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("circle-info")}
                        />,
                    route: "/about"
                }
            ]
        },
        {
            id: 3,
            title: "Feedback",
            collapse: true,
            links: [
                {
                    id: 0,
                    name: "ASAP Survey",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("truck-pickup")}
                        />,
                    link: "https://forms.gle/MbZVPn89NbW5n1Lz5"
                },
                {
                    id: 1,
                    name: "Rest Area Survey",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("shop")}
                        />,
                    link: "https://forms.gle/FKm2YatCWLoBXQu57"
                },
                {
                    id: 2,
                    name: "Comments",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("comment")}
                        />,
                    route: "/comments"
                }
            ]
        }
    ],
};

export const navBarMap: INavMap = {
    navSections: [
        {
            id: 0,
            title: "Traveler Information",
            collapse: true,
            links: [
                {
                    id: 0,
                    name: "Map",
                    iconComp: 
                        <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("map-marker-alt")}
                        />,
                    route: "/map"
                },
                {
                    id: 1,
                    name: "Cameras",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("video")}
                        />,
                    route: "/cameras"
                },
                {
                    id: 2,
                    name: "Videoboards",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("presentation-screen")}
                        />,
                    route: "/videoboards"
                },
                {
                    id: 3,
                    name: "Planner",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("route")}
                        />,
                    route: "/trip-planner"
                },
                {
                    id: 4,
                    name: "More",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={regular("bars")}
                        />,
                    route: "/more"
                }
            ]
        },
    ],
};

export const moreNavBarMap: INavMap = {
    navSections: [
        {
            id: 0,
            title: "Traveler Information",
            collapse: true,
            links: [
                {
                    id: 7,
                    name: "Legend",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/legend"
                },
                {
                    id: 1,
                    name: "Traffic Reports",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/traffic-reports"
                },
                {
                    id: 2,
                    name: "Travel Times",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/travel-times"
                },
                {
                    id: 5,
                    name: "Settings",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/settings"
                }
            ]
        },
        {
            id: 1,
            title: "Resources",
            collapse: true,
            links: [
                {
                    id: 0,
                    name: "Documents",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/documents"
                },
                {
                    id: 1,
                    name: "External Links",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/external-links"
                },
                {
                    id: 2,
                    name: "Law Enforcement Alerts",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/law-enforcement-alerts"
                },
                {
                    id: 3,
                    name: "Personalized Alerts",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faExternalLink}
                        />,
                    link: "https://www.algotrafficpta.com"
                },
                {
                    id: 4,
                    name: "Social Media",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/social-media"
                },
                {
                    id: 5,
                    name: "About",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/about"
                }
            ]
        },
        {
            id: 2,
            title: "Feedback",
            collapse: true,
            links: [
                {
                    id: 0,
                    name: "ASAP Survey",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faExternalLink}
                        />,
                    link: "https://forms.gle/MbZVPn89NbW5n1Lz5"
                },
                {
                    id: 1,
                    name: "Rest Area Survey",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faExternalLink}
                        />,
                    link: "https://forms.gle/FKm2YatCWLoBXQu57"
                },
                {
                    id: 2,
                    name: "Comments",
                    iconComp: <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronRight}
                        />,
                    route: "/comments"
                }
            ]
        }
    ],
};

export default navPanelMap;