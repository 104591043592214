// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { whenSmallMax } from "~/theme";

export const OverlayWrapper = styled.div`
    width: auto;
    height: auto;
`;

export const TopRow = styled.div<{editVideoboard?: boolean}>`
    height: auto;
    width: auto;

    position: absolute;
    top: ${props => !props.editVideoboard ? "10px" : "5px"};
    left: 10px;
    z-index: 2;

    ${flexSmart("flex-start center")}
`;

export const BottomRow = styled.div`
    height: auto;
    width: 100%;

    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;

    ${flexSmart("flex-end center")}
`;

export const RoutesOverlay = styled.div<{editVideoboard?: boolean}>`
    padding: 4px 8px;
    box-sizing: border-box;

    border-radius: ${props => !props.editVideoboard ? "8px" : "3px"};

    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);
`;

export const Primary = styled.div<{editVideoboard?: boolean}>`
    height: auto;
    width: auto;

    font-weight: 600;

    ${props => !props.editVideoboard ? 
        `
            font-size: 20px;
            line-height: 24px;
        ` 
        : 
        `
            font-size: 6px;
            line-height: 8px;
        `};
   

    ${whenSmallMax(`
        font-size: 14px;
        line-height: 15px;
    `)}
`;

export const Cross = styled.div<{editVideoboard?: boolean}>`
    height: auto;
    width: auto;

    font-weight: 400;

    ${props => !props.editVideoboard ? 
        `   
            font-size: 20px;
            line-height: 24px;
        ` 
        : 
        `
            font-size: 6px;
            line-height: 8px;
        `};

    ${whenSmallMax(`
        font-size: 12px;
        line-height: 13px;
    `)}
`;

export const TagOverlay = styled.div`
    height: auto;
    width: auto;

    padding: 4px 8px;

    border-radius: 6px;

    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);
`;

export const Tag = styled.div``;