// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS, SS_HEIGHT, SS_WIDTH } from "~/constants";

export const SnapshotWrapper = styled.div<{isClickable?: boolean, editVideoboard?: boolean}>`
    ${props => !props.editVideoboard ?
        `
            height: ${SS_HEIGHT}px;
            width: ${SS_WIDTH}px;
            border-radius: ${BORDER_RADIUS}px;

            > img {
                border-radius: ${BORDER_RADIUS}px;
            }
        `
    :
        `
            border-radius: 4px;

            > img {
                border-radius: 4px;
            }
        `
    }

    position: relative;

    ${flexSmart("space-between center")}
    flex-direction: column;

    : hover{
        ${props => props.isClickable ? "cursor: pointer;" : ""}
    }
`;

export const FloatingPlayBtn = styled.div`
    position: absolute;
    left: calc(50% - 26px);
    top: calc(50% - 26px);
    z-index: 3;
`;

export const Overlay = styled.div<{bottom?: boolean}>`
    padding: ${props => props.bottom ? '5px' : "6px 10px"};
    box-sizing: border-box;

    border-radius: 8px;

    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);

    : hover{
        cursor: pointer;
    }
`;

export const SettingsWrapper = styled.div`
    ${flexSmart("flex-start center")}
    gap: 5px;
`;

export const Primary = styled.div`
    height: auto;
    width: auto;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
`;

export const BottomRow = styled.div`
    height: auto;
    width: auto;

    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;

    ${flexSmart("flex-start center")}
`;