// libraries
import * as React from "react";
// styles
import PageTemplate from "~/components/views/page-template/PageTemplate";
import * as PageStyles from "~/components/views/page-template/Styled";
// component
import { useVideoboardData } from "./hooks";
import DetailsSection, { EDetailDisplayType } from "~/components/views/data-search-overlay/data-type-sections/detail-section/DetailsSection";
import NoResultsTemplate from "~/components/views/page-template/NoResultsTemplate";
import { EAlgoApiObjectType } from "~/interfaces";
import { useSelector } from "react-redux";
import DeleteVideoboardErrorModalContext from "~/contexts/DeleteVideoboardErrorModalContext";
import DeleteErrorModal from "~/components/views/modal/delete-error-modal/DeleteErrorModal";

export type IProps = {
    //
};

export const VideoboardsPage: React.FC<IProps> = (props) => {

    const [ searchText, setSearchText ] = React.useState<string>("");

    useVideoboardData(searchText);
    const videoboardStore = useSelector((state: any) => state.videoboard);
    const filteredVideoboards = videoboardStore.data;
    const isLoadingContent: boolean = videoboardStore.loading;

    const deleteVideoboardErrorContext: any = React.useContext(DeleteVideoboardErrorModalContext);

    React.useEffect(() => {
        if(videoboardStore.deleted === false) {
            deleteVideoboardErrorContext.setModalContent(
                <DeleteErrorModal doneCallback={() => deleteVideoboardErrorContext.setShowModal(false)} />
            );
            deleteVideoboardErrorContext.setShowModal(true);
        }
    },[videoboardStore.deleted]);

    return (
        <PageTemplate 
            title={"Videoboards"}
            videoboards={true}
            searchBar={{
                autoSubmit: true,
                submitCallback: (newText: string) => 
                    setSearchText(newText)
            }}
        >
            <PageStyles.FilteredContentSection>

                { !isLoadingContent && filteredVideoboards && filteredVideoboards.length > 0 &&
                    <DetailsSection dataObjects={filteredVideoboards} showAll={true}
                        dataType={EAlgoApiObjectType.videoboard} displayType={EDetailDisplayType["videoboard-snapshot"]} />
                }

                { (isLoadingContent || !filteredVideoboards || !filteredVideoboards?.length || filteredVideoboards?.length <= 0) &&
                    <NoResultsTemplate loading={isLoadingContent} pageType="Videoboards" />
                }

            </PageStyles.FilteredContentSection>
        </PageTemplate>
    );
};

export default VideoboardsPage;