// libraries
import { createSlice } from "@reduxjs/toolkit";
// types & models
import { EAlgoApiObjectType, IFeature, IGeometry } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS, API_V4 } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
import { getAccessToken } from "~/authentication/oidcConfig";
// test data
import T_DATA from "../test-data/cameras/all-cameras-10-6-22.json";
import T_DATA_GROUPED from "../test-data/cameras/cameras-for-event.json";
import { buildLastResponse } from "~/store/library";
import { IATCameraDto } from "@algo/network-manager/models/v4";
import { getAllButFirstCameraFromGroup, getFirstCameraFromGroup } from "./camera-group";
import { IProcessedResponse, TrafficEventNetworkManager, WeatherAlertNetworkManager } from "@algo/network-manager/managers/v4";
import { FacilityNetworkManager } from "@algo/network-manager/managers/v3";

declare var __API_URL__: string;
const facilityUrl: string = 
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).facilities}`;
const trafficEventUrl: string = 
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).trafficEvents}`;
const weatherAlertUrl: string = 
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).weatherAlerts}`;
const zoneAlertUrl: string = 
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).zones}`;

export type INEARBY_CAMERAS_INITIAL_STATE = {
    [key: string]: {
        [key: number]: IATCameraDto[]
    }
}

export const INITIAL_STATE: INEARBY_CAMERAS_INITIAL_STATE = {

}

// create list slice
export const nearbyCamerasSlice = createSlice(
    {
        name: "nearby-cameras",
        initialState: INITIAL_STATE,
        reducers: {
            begin: (state: any) => {
                state.loading = true;
            },
            success: (state: any, action: any) => {
                state.loading = false;
                state[action.payload.type] = {
                    [action.payload.id]: action.payload.cameras
                } 
            },
            failure: (state: any) => {
                state.loading = false;
            }
        }
    }
);

// get handles for the slice's actions
const { 
    begin, success, failure
} = nearbyCamerasSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getNearbyCameras = (object: any, objectType: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {        
        // logic to acquire the nearby cameras for a given object
        const zoneAlert = object?.properties?.type === "zoneAlert";
        switch(objectType){
            case EAlgoApiObjectType["camera-group"]:
                dispatch(begin());
                dispatch(
                    success(
                        { 
                            type: objectType, 
                            id: getFirstCameraFromGroup(object)?.id, 
                            cameras: getAllButFirstCameraFromGroup(object) 
                        }
                    )
                );
                break;
            default: 
                if (isTesting) 
                    dispatch(getNearbyCameras_test(object, objectType, mapTypeToManager(objectType, zoneAlert)) )
                else 
                    dispatch(getNearbyCameras_api(object, objectType, mapTypeToManager(objectType, zoneAlert) ) );
        }
    }
};

const mapTypeToManager = (type: string, zoneAlert?: boolean) => {

    if(zoneAlert) return new WeatherAlertNetworkManager(zoneAlertUrl);
    switch(type){
        case "traffic-event":
            return new TrafficEventNetworkManager(trafficEventUrl);
        case "weather-alert":
            return new WeatherAlertNetworkManager(weatherAlertUrl);
        case "state-facility":
            return new FacilityNetworkManager(facilityUrl);
    }
}

const getNearbyCameras_test = (object: any, objectType: string, manager: any) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());
        
        dispatch(
            success(
                {
                    type: objectType,
                    id: object.id,
                    cameras: T_DATA_GROUPED
                }
            )
        );

    }
};

const getNearbyCameras_api = (object: any, objectType: string, manager: any) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.getCameras(
                    buildLastResponse(getState()[objectType]), 
                    { id: object.id, includeGeometry: true },
                    false
                )
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error){
                            dispatch(failure())
                        }
                        else {
                            let features: IFeature[] = response.data?.features;
                            let data: any[] = [];
                            let geometries: IGeometry[] = [];
    
                            features?.forEach( (feature: IFeature) => { 
                                geometries.push(feature.geometry); 
                                data.push(
                                    {...feature.properties, 
                                        coord: {
                                            lat: feature.geometry.coordinates[1], 
                                            lng: feature.geometry.coordinates[0]
                                        },
                                        id: feature.properties.id
                                    }
                                ); 
                            });

                            dispatch(
                                success(
                                    {
                                        type: objectType,
                                        id: object.id,
                                        cameras: data
                                    }
                                )
                            );
                        }
                    }
                ).catch(
                    (error: Error) => dispatch(failure())
                )
            }
        ).catch(
            (err: any) => {
                console.error("Error retrieving user access token.");
                manager.getCameras(
                    buildLastResponse(getState()[objectType]), 
                    { id: object.id, includeGeometry: true },
                    false
                )
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error){
                            dispatch(failure())
                        }
                        else {
                            dispatch(
                                success(
                                    {
                                        type: objectType,
                                        id: object.id,
                                        cameras: response.data
                                    }
                                )
                            );
                        }
                    }
                ).catch(
                    (error: Error) => dispatch(failure())
                )
            }
        )
    }
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const nearbyCamerasReducer = nearbyCamerasSlice.reducer;