import { AAUserAuthorizationProfile } from '@algo/network-manager/models/v3/admin';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  element: React.ReactElement;
  privilege: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, privilege }) => {
    const auth = useAuth();
    const userStore = useSelector((state: any) => state.users);
    const [hasPrivilege, setHasPrivilege] = React.useState<boolean | null>(null);
  
    React.useEffect(() => {
      const checkPrivileges = () => {
        if (!auth.isAuthenticated && !auth.isLoading) {
          setHasPrivilege(false);
        } else if (userStore.profile) {
          const profile = new AAUserAuthorizationProfile(userStore.profile);
          setHasPrivilege(profile.hasPrivilege(privilege));
        } else {
          setHasPrivilege(null);
        }
      };
  
      checkPrivileges();
    }, [userStore.profile, auth, privilege]);
  
    // Show loading state if privileges are still being determined
    if (hasPrivilege === null) {
      return <div />; // Optionally show a loading spinner
    }
  
    // Redirect if user does not have the required privilege
    return hasPrivilege ? element : <Navigate to="/access_denied" />;
  };
  
  export default PrivateRoute;