// libraries
import { 
    commonFilterBySearch, createAlgoSlice 
} from "./common";
// types & models
import { IProcessedResponse, VideoBoardNetworkManager } from "@algo/network-manager/managers/v4";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { API_V4, CUR_API_ENDPOINTS, CUR_API_VERSION } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
import { getAccessToken } from "~/authentication/oidcConfig";
import { buildLastResponse } from "~/store/library";
// test data
import T_DATA_1 from "~/store/algo-api/test-data/videoboards/all-videoboards-10-10-24.json";
import T_DATA_2 from "~/store/algo-api/test-data/videoboards/response_1728574610245.json";
import { ATVideoBoard } from "@algo/network-manager/models/v4";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).videoboards}`;

// create list slice
export const VideoboardSlice = 
    createAlgoSlice(EAlgoApiObjectType.videoboard);

// get handles for the slice's actions
const { 
    begin, success, failure, successHead, 
    beginDelete, successDelete, failureDelete,
    beginSave, successSave, failureSave
} = VideoboardSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, headOnly?: boolean, test: boolean = isTesting, testMode?: string): any =>{

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType.videoboard].loading) return;

        if (test){
            if (headOnly)
                dispatch(getById_head_test(id, testMode));
            else
                dispatch(getById_test(id, testMode));
        }
        else{
            if(headOnly)
                dispatch(getById_head_api(id));
            else
                dispatch(getById_api(id));
        }
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;

        dispatch(beginFunction());

        let manager: VideoBoardNetworkManager = new VideoBoardNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.getById(buildLastResponse(getState()), {id}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else 
                            dispatch(successFunction(response.getReduxObject()))
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        ).catch(
            (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
        )
    }

};

// retrieves data from api for this data type
export const getById_head_api = (id: number) => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let successFunction = successHead;
        let failureFunction = failure;

        let manager: VideoBoardNetworkManager = new VideoBoardNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.getById(buildLastResponse(getState()), {id}, true)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else 
                            dispatch(successFunction(response.getReduxObject()))
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        ).catch(
            (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
        )
    }

};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("videoboard: getById_test is not yet implemented.");
};

// retrieves test data for this data type
export const getById_head_test = (id: number, mode?: string) => {
    
    alert("videoboard: getById_head_test is not yet implemented.");
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (headOnly?: boolean, query?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType.videoboard].loading) return;

        if (test){
            if (headOnly)
                dispatch(getAll_head_test());
            else
                dispatch(getAll_test());
        }
        else{
            if(headOnly)
                dispatch(getAll_head_api(query));
            else
                dispatch(getAll_api(query));
        }
    }
}

// retrieves all data from api for this data type
export const getAll_api = (query?: string) => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;

        dispatch(beginFunction());

        let manager: VideoBoardNetworkManager = new VideoBoardNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.getAll(buildLastResponse(getState()), {query: query}, false)
                    .then(
                        (response: IProcessedResponse) => {
                            if (response.error)
                                dispatch(failureFunction({ errorMessage: response.error.message}))
                            else 
                                dispatch(successFunction(response.getReduxObject()))
                        }
                    ).catch(
                        (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                    )
            }
        ).catch(
            (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
        )
    }
};

export const getAll_head_api = (query?: string) => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let successFunction = successHead;
        let failureFunction = failure;

        let manager: VideoBoardNetworkManager = new VideoBoardNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.getAll(buildLastResponse(getState()), {query: query}, true)
                    .then(
                        (response: IProcessedResponse) => {
                            if (response.error)
                                dispatch(failureFunction({ errorMessage: response.error.message}))
                            else 
                                dispatch(successFunction(response.getReduxObject()))
                        }
                    ).catch(
                        (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                    )
            }
        ).catch(
            (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
        )
    }
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA_2, errorMessage: null, status: 200}))
            }, 2500
        );
    }
};

export const getAll_head_test = (mode?: string) => {
    alert("videoboard: getAll_head_test is not yet implemented.");
};

// handles dispatching a delete by id from either api or test source based on args
export const deleteVideoboard = (id: number, test: boolean = isTesting, testMode?: string): any =>{

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(delete_test(id, testMode));
        else 
            dispatch(delete_api(id));
    }
}

// deletes data from api for this data type
export const delete_api = (id: number) => {
    return (
        dispatch: any,
        getState: any
    ) => {
        
        let beginFunction = beginDelete;
        let successFunction = successDelete;
        let failureFunction = failureDelete;

        dispatch(beginFunction());

        let manager: VideoBoardNetworkManager = new VideoBoardNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.delete({id})
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else {
                            dispatch(successFunction(response.getReduxObject()));
                            dispatch(getAll_api());
                        }
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        ).catch(
            (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
        )
    }

};

// deletes test data for this data type
export const delete_test = (id: number, mode?: string) => {
    
    alert("videoboard: delete_test is not yet implemented.")
};

// handles dispatching an update by id from either api or test source based on args
export const updateVideoboard = (id: number, videoboard: ATVideoBoard, test: boolean = isTesting, testMode?: string): any =>{

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(update_test(id, videoboard, testMode));
        else 
            dispatch(update_api(id, videoboard));
    }
}

// updates data from api for this data type
export const update_api = (id: number, videoboard: ATVideoBoard) => {
    return (
        dispatch: any,
        getState: any
    ) => {
        
        let beginFunction = beginSave;
        let successFunction = success;
        let failureFunction = failureSave;

        dispatch(beginFunction());

        let manager: VideoBoardNetworkManager = new VideoBoardNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.update({id, videoboard})
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else {
                            dispatch(successFunction(response.getReduxObject()));
                        }
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        ).catch(
            (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
        )
    }

};

// updates test data for this data type
export const update_test = (id: number, videoboard: ATVideoBoard, mode?: string) => {
    
    alert("videoboard: update_test is not yet implemented.")
};

// handles dispatching an save from either api or test source based on args
export const saveVideoboard = (videoboard: ATVideoBoard, test: boolean = isTesting, testMode?: string): any =>{

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(save_test(videoboard, testMode));
        else 
            dispatch(save_api(videoboard));
    }
}

// saves data from api for this data type
export const save_api = (videoboard: ATVideoBoard) => {
    return (
        dispatch: any,
        getState: any
    ) => {
        
        let beginFunction = beginSave;
        let successFunction = success;
        let failureFunction = failureSave;

        dispatch(beginFunction());

        let manager: VideoBoardNetworkManager = new VideoBoardNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.create({videoboard})
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else {
                            dispatch(successFunction(response.getReduxObject()));
                        }
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        ).catch(
            (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
        )
    }

};

// saves test data for this data type
export const save_test = (videoboard: ATVideoBoard, mode?: string) => {
    
    alert("videoboard: save_test is not yet implemented.")
};

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of object for geo-filtering
export const getCoords = (
    item: any
): {lat: number, lng: number} | null => {

    let lat: number = item.coord.lat;
    let lng: number = item.coord.lng;

    if (lat && lng) 
        return {lat, lng}

    else 
        return null;

};

// exports the slice's reducer ( used in store file to build up master reducer )
export const VideoboardReducer = VideoboardSlice.reducer;