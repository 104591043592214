// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
import SiteNavContext from "~/navigation/SiteNavContext";
// components

export type IProps = {
    id: number;
    doneCallback: () => void;
};

export const NoCameraModal: React.FC<IProps> = (props) => {

    const {
        id,
        doneCallback
    } = props;

    const navContext: any = React.useContext(SiteNavContext);
    
    const [ editVideoboard, setEditVideoboard ] = React.useState<boolean>(false);
    const userStore = useSelector( (state: any) => state.users);
    React.useEffect(() => {
        if (userStore.profile){
            let profile: AAUserAuthorizationProfile = new AAUserAuthorizationProfile(userStore.profile);
            setEditVideoboard(profile.hasPrivilege("EditVideoboard"));
        }
    }, [userStore.profile]);
    
    return (
        <SC.StyledModal>

            <FontAwesomeIcon
                className="fa-fw"
                icon={faTriangleExclamation}
                size={"5x"}
                color={"#FFCC00"}
            />

            <SC.TextSection>
                <SC.BoldText>This videoboard has no active camera.</SC.BoldText>
                <SC.Text>Please edit the videoboard.</SC.Text>
            </SC.TextSection>

            <SC.ButtonSection>
                <SC.StyledButton cancel={true} onClick={doneCallback}>
                    {"Cancel"}
                </SC.StyledButton>

                <SC.StyledButton disabled={!editVideoboard} onClick={() => {
                            navContext.setSelectedSiteLink([1,0]); 
                            navContext.setSelectedRoute("/videoboards/editor/" + id);
                            doneCallback();
                        }}>
                    <Fragment>
                        {"Edit Videoboard"}
                    </Fragment>
                </SC.StyledButton>
            </SC.ButtonSection>

        </SC.StyledModal>
    )
}

export default NoCameraModal;