// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import AutoSizer from 'react-virtualized-auto-sizer';
// styles
import * as SC from "./Styled";
// types & models
import { ATCamera, IATCameraDto } from "@algo/network-manager/models/v4";
// components
import Snapshot from "~/components/views/snapshot/Snapshot";
// constants
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { ThemeContext } from "styled-components";

export type IProps = {
    objectList?: any[];
    onDragStart?: (event: React.DragEvent<HTMLDivElement>, cameraId: number | undefined) => void;
};

export const EditVideoboardSnapshotSection: React.FC<IProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);

    const listRef = React.useRef<any>(null);
    const itemWidth = 180;
    const [offset, setOffset] = React.useState<number>(0);

    const scrollTo = (offsetParam: number) => {
        const newOffset = offset + offsetParam;
        if (listRef.current) {
            listRef.current.scrollTo(newOffset);
        }
    };
    
    if (!nuonoe(props.objectList)) return null;

    return (
        <SC.Wrapper>
            <SC.ArrowButton onClick={() => {scrollTo(-itemWidth)}}>
                <FontAwesomeIcon icon={faChevronLeft} color={theme.colors["primary"].rgba} size={"2x"} />
            </SC.ArrowButton>
            <SC.ObjectsDiv>
                <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => {
                        const itemCount = props.objectList ? props.objectList.length : 0;
                        const totalOffset = itemCount * itemWidth - width;
                        return (                
                            <SC.StyledList
                            ref={listRef}
                            height={height}
                            itemCount={itemCount}
                            itemSize={itemWidth}
                            width={width}
                            layout="horizontal"
                            onScroll={
                                ({scrollDirection,
                                  scrollOffset,
                                  scrollUpdateWasRequested}) => {if(scrollOffset < totalOffset) setOffset(scrollOffset);}}
                        >
                            {({ index, style }) => {
                                const camera: IATCameraDto = props.objectList && index >= 0 ? props.objectList[index] : null;

                                return (
                                    <div style={style}>
                                        {camera && (
                                            <SC.SnapshotWrap>
                                                <Snapshot
                                                    key={camera.id}
                                                    width={"170px"}
                                                    height={"100px"}
                                                    editVideoboard={true}
                                                    camera={new ATCamera(camera)}
                                                    showPlayButton={false}
                                                    onDragStart={props.onDragStart}
                                                />
                                            </SC.SnapshotWrap>
                                        )}
                                    </div>
                                );
                            }}
                        </SC.StyledList>
                    )}}
                </AutoSizer>
            </SC.ObjectsDiv>
            <SC.ArrowButton onClick={() => {scrollTo(itemWidth)}}>
                <FontAwesomeIcon icon={faChevronRight} color={theme.colors["primary"].rgba} size={"2x"} />
            </SC.ArrowButton>
        </SC.Wrapper>
    );
};

export default EditVideoboardSnapshotSection;