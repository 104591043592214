// libraries
import React, { Fragment } from "react";
// hooks & context
import { ThemeContext } from "../../../theme";
// styles
import * as SC from "./Styled";
// components
import { faPlay } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IATVideoBoardDto } from "@algo/network-manager/models/v4";
import VideoboardModal from "../modal/videoboard-modal/VideoboardModal";
import VideoboardManager from "../videoboard/VideoboardManager";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";
import NoCameraModal from "../modal/no-camera-modal/NoCameraModal";
import NoCameraModalContext from "~/contexts/NoCameraModalContext";

export type IProps = {
    videoboard?: IATVideoBoardDto;
};

export const PlayVideoboardButton: React.FC<IProps> = (props) => {

    const { videoboard } = props;
    const theme: any = React.useContext(ThemeContext);
    const videoboardModalContext: any = React.useContext(VideoboardModalContext);
    const noCameraModalContext: any = React.useContext(NoCameraModalContext);
    
    const playClickHandler = (videoboard: IATVideoBoardDto | undefined) => {
        if(videoboard)
        {
            const SingleVM: VideoboardManager = new VideoboardManager(videoboard);

            videoboardModalContext.setModalContent(
                <VideoboardModal 
                    videoboard={SingleVM.videoboard}
                    isVideoboard={true}
                    doneCallback={() => videoboardModalContext.setShowModal(false)} />
            );
            videoboardModalContext.setShowModal(true);
        }

    }
    
    const noCamerasClickHandler = (id: number | undefined) => {
        if(id)
        {
            noCameraModalContext.setModalContent(
                <NoCameraModal id={id} doneCallback={() => noCameraModalContext.setShowModal(false)} />
            );
            noCameraModalContext.setShowModal(true);
        }
    }


    const getCamera = (videoboard: IATVideoBoardDto | undefined) => {
        let camera;
        if (videoboard && videoboard.pages) {
            for (const page of videoboard.pages) {
                if (page.items) {
                    for (const item of page.items) {
                        if (item.camera) {
                            camera = item.camera;
                            break; // Exit the loop once we find the first camera
                        }
                    }
                }
                if (camera) break; // Exit the outer loop if we found a camera
            }
        }
        return camera;
    }

    const availableCamera = getCamera(videoboard);

    return (
        <div>
            {
                
                <SC.StyledButton onClick={(event: any) => { 
                    availableCamera? playClickHandler(videoboard) : noCamerasClickHandler(videoboard?.id); }}>
            
                    <Fragment>
                        <FontAwesomeIcon
                            className="fa-fw"
                            icon={faPlay}
                            size={"1x"}
                            color={theme.colors["text-secondary"].rgba}
                        />
                        {"Play"}
                    </Fragment>

                </SC.StyledButton>
            }
        </div>
        
    );
};

export default PlayVideoboardButton;