// libraries
import * as React from "react";
import { useNavigate } from "react-router-dom";
// styles
import * as SC from "./Styled";
// components
import BackButton from "../back-button/BackButton";
import SearchBar from "../search-bar/SearchBar";
import { useBreakpoint } from "~/library/useBreakpoint";
import TravelTimesLegend from "~/components/pages/map/map-overlay-views/legend-section/legends/TravelTimesLegend";
import AddVideoboardButton from "../add-videoboard-button/AddVideoboardButton";
import SaveVideoboardButton from "../save-videoboard-button/SaveVideoboardButton";
import TransitionTimer from "../transition-timer/TransitionTimer";
import PlayVideoboardButton from "../play-videoboard-button/PlayVideoboardButton";
import { IATVideoBoardDto } from "@algo/network-manager/models/v4";
// constants
import { TITLE_PLACEHOLDER } from "~/constants";


export type ISearchBarProps = {
    autoSubmit?: boolean;
    submitCallback?: (searchText: string) => void;
};

export type IProps = {
    title?: string;
    children?: React.ReactNode;
    backButton?: boolean;
    backButtonCallback?: () => void;
    searchBar?: ISearchBarProps;
    travelTimes?: boolean;
    videoboards?: boolean;
    edit?:boolean;
    onTitleChange?: (val: string) => void;
    timer?: number;
    onTimeChange?: (val: number) => void;
    onSave?: () => void;
    isSaving?: boolean;
    playVideoboard?: IATVideoBoardDto;
};

export const PageTemplate: React.FC<IProps> = (props) => {

    const { title, backButton, backButtonCallback, searchBar, timer, onTimeChange,
        travelTimes, videoboards, edit, onTitleChange, onSave, isSaving, playVideoboard} = props;

    const navigate = useNavigate();
    const { lg: isLargeMax } = useBreakpoint();

    return (
        <SC.StyledPageTemplate>
            
            {   (title || backButton || travelTimes || videoboards || edit) &&
                <SC.BackAndTitleWrap travelTimes={travelTimes} videoboards={videoboards}>

                    {  backButton &&
                        <BackButton callback={() => backButtonCallback && backButtonCallback()} />
                    }


                    { title && !edit &&
                        <SC.Title>
                            {title}
                        </SC.Title>
                    }

                    { travelTimes &&
                        <SC.StyledLegend>
                            <TravelTimesLegend />
                        </SC.StyledLegend> 
                    }

                    { !isLargeMax && videoboards &&
                        <AddVideoboardButton />
                    }

                    { edit && 
                        <SC.EditWrapper>
                            <SC.TitleInput id={"title"}
                                placeholder={TITLE_PLACEHOLDER}
                                value={title || ''}
                                onChange={(evt: any) => props.onTitleChange?.(evt.currentTarget.value)}/>
                            <TransitionTimer pageOnSecond={timer} onTimeChange={onTimeChange}/>
                            <PlayVideoboardButton videoboard={playVideoboard}/>
                            <SaveVideoboardButton isSaving={isSaving} callback={() => {onSave && onSave()}} title={title} />
                        </SC.EditWrapper>
                    }

                </SC.BackAndTitleWrap>
            }

            { searchBar && 
                <SC.SearchRow>
                    <SearchBar autoSubmit={searchBar.autoSubmit}
                        submitCallback={
                            (evt: any, searchText: string) => { 
                                if (searchBar.submitCallback)
                                    searchBar.submitCallback(searchText);
                            }
                        }
                    />
                </SC.SearchRow>
            }

            <SC.Content>
                {props.children}
            </SC.Content>

        </SC.StyledPageTemplate>
    );
};

export default PageTemplate;