// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import AutoSizer from 'react-virtualized-auto-sizer';
// styles
import * as SC from "./Styled";
// types & models
import { IATVideoBoardDto } from "@algo/network-manager/models/v4";
// components
import VideoboardSnapshot from "~/components/views/videoboard-snapshot/VideoboardSnapshot";
import VideoboardModal from "~/components/views/modal/videoboard-modal/VideoboardModal";
import VideoboardManager from "~/components/views/videoboard/VideoboardManager";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";
// constants
import { SS_ROW_HEIGHT, SS_WIDTH } from "~/constants";
import NoCameraModalContext from "~/contexts/NoCameraModalContext";
import NoCameraModal from "~/components/views/modal/no-camera-modal/NoCameraModal";

export type IProps = {
    objectList?: any[]
};

export const VideoboardSnapshotSection: React.FC<IProps> = (props) => {

    const videoboardModalContext: any = React.useContext(VideoboardModalContext);
    const noCameraModalContext: any = React.useContext(NoCameraModalContext);

    const playOneClickHandler = (videoboard: IATVideoBoardDto) => {
        const SingleVM: VideoboardManager = new VideoboardManager(videoboard);

        videoboardModalContext.setModalContent(
            <VideoboardModal 
                videoboard={SingleVM.videoboard}
                isVideoboard={true}
                doneCallback={() => videoboardModalContext.setShowModal(false)} />
        );
        videoboardModalContext.setShowModal(true);
    }

    const noCamerasClickHandler = (id: number) => {

        noCameraModalContext.setModalContent(
            <NoCameraModal id={id} doneCallback={() => noCameraModalContext.setShowModal(false)} />
        );
        noCameraModalContext.setShowModal(true);
    }

    const getCamera = (videoboard: IATVideoBoardDto) => {
        let camera;
        if (videoboard && videoboard.pages) {
            for (const page of videoboard.pages) {
                if (page.items) {
                    for (const item of page.items) {
                        if (item.camera) {
                            camera = item.camera;
                            break; // Exit the loop once we find the first camera
                        }
                    }
                }
                if (camera) break; // Exit the outer loop if we found a camera
            }
        }
        return camera;
    }
    
    if (!nuonoe(props.objectList)) return null;

    return (
        <SC.Container>
            <SC.StyledAutoSizer>
                <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => { 
                        const itemCount = props.objectList ? props.objectList.length : 0;
                        const columnNumber = Math.floor(width / (SS_WIDTH + 30)) != 0 ? Math.floor(width / (SS_WIDTH + 30)) : 1;
                        const rowCount = Math.ceil(itemCount / columnNumber);
                        const columnWidth = Math.floor(width / columnNumber);

                        return(
                            <SC.StyledGrid
                                columnCount={columnNumber}
                                rowCount={rowCount}
                                height={height}
                                width={width}
                                columnWidth={columnWidth}
                                rowHeight={SS_ROW_HEIGHT}
                            >
                                {
                                    ({ columnIndex, rowIndex, style }) => {
                                        const index = rowIndex * columnNumber + columnIndex;
                                        const videoboard: IATVideoBoardDto = props.objectList && index >= 0 ? props.objectList[index] : null;
                                        const camera = getCamera(videoboard);

                                        return (
                                            <div style={style}>
                                                {videoboard && (
                                                    <SC.SnapshotWrap>
                                                        <VideoboardSnapshot
                                                            key={videoboard.id}
                                                            videoboard={videoboard}
                                                            camera={camera}
                                                            showPlayButton={true}
                                                            clickCallback={() => {
                                                                camera ? playOneClickHandler(videoboard) : noCamerasClickHandler(videoboard.id);
                                                            }}
                                                        />
                                                    </SC.SnapshotWrap>
                                                )}
                                            </div>
                                        );
                                    }
                                }
                            </SC.StyledGrid>

                        )
                    }}
                </AutoSizer>
            </SC.StyledAutoSizer>
        </SC.Container>
    );
};

export default VideoboardSnapshotSection;