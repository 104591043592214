// libraries
import { createAlgoSlice } from "./common";
import { buildLastResponse } from "~/store/library";
// types & models
import { RouteNetworkManager, IRouteNetworkManager, IProcessedResponse, IStoreProcessedResponse } from "@algo/network-manager/managers/v3";
import { IATRouteRequest } from "@algo/network-manager/models/v3";
import { EAlgoApiObjectType, IFeature, IGeometry } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS, API_V4 } from "../../api-endpoint-strings";
import { isTesting } from "~/constants";
import T_DATA from "../test-data/route-cameras/route-cameras-05-15-23.json";
import { getAccessToken } from "~/authentication/oidcConfig";

declare var __API_URL__: string;
const apiUrl: string =
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).routes}`;
const cameraApiUrl: string =
    `${__API_URL__}/${API_V4}/${CUR_API_ENDPOINTS(API_V4).routes}`;

// create list slice
export const routeSlice =
    createAlgoSlice(EAlgoApiObjectType["route"]);

// get handles for the slice's actions
const {
    begin, successRoute, successRouteLoading, failure,
    failureRouteTrafficEvents, failureRouteCameras,
    successRouteCameras, successRouteTrafficEvents,
    beginRouteCameras, beginRouteTrafficEvents, resetRoute
} = routeSlice.actions;

// handles dispatching a generate routes request from either api or test source based on args
export const generateRoutes = (routeRequest: IATRouteRequest, mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["route"]].loading) return;

        if (test)
            dispatch(generate_routes_test(routeRequest, mode));
        else
            dispatch(generate_routes_api(routeRequest, mode));
    }
}

// retrieves all data from api for this data type
export const generate_routes_api = (routeRequest: IATRouteRequest, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        let beginFunction = begin;
        let successFunction = successRoute;
        let successLoadingFunction = successRouteLoading;
        let failureFunction = failure;

        dispatch(beginFunction());

        let manager: IRouteNetworkManager = new RouteNetworkManager(apiUrl);
        let routeRequestObject: IATRouteRequest = {
            origin: routeRequest.origin,
            destination: routeRequest.destination,
            units: routeRequest.units
        };

        manager.generateRoutes({ routeRequest: routeRequestObject })
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message}))
                    else {
                        dispatch(successFunction( { ...response.getReduxObject(), data: response.data.features} ))
                        dispatch(getTrafficEvents(response?.data?.features[0]?.properties?.data?.id))
                        setTimeout(() => dispatch(successLoadingFunction( { ...response.getReduxObject(), data: response.data.features} )), 5000)
                    }
                }
            ).catch(
                (error: Error) => dispatch(failureFunction({errorMessage: error.message}))
            );
    }
};

// retrieves test data for this data type
export const generate_routes_test = (routeRequest: IATRouteRequest, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        alert("routes: generate_routes_test is not yet implemented.");
    }
};

export const resetRoutes = (): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(resetRoute());
    }
};

// handles dispatching a get traffic events request from either api or test source based on args
export const getTrafficEvents = (routeId: string, headOnly?: boolean, mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["route"]].trafficEventsLoading) return;

        if (test){
            if (headOnly)
                dispatch(get_traffic_events_head_test(routeId, mode));
            else
                dispatch(get_traffic_events_test(routeId, mode));
        }
        else{
            if(headOnly)
                dispatch(get_traffic_events_head_api(routeId, mode));
            else
                dispatch(get_traffic_events_api(routeId, mode));
        }
    }
}

// retrieves all data from api for this data type
export const get_traffic_events_api = (routeId: string, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        let beginFunction = beginRouteTrafficEvents;
        let successFunction = successRouteTrafficEvents;
        let failureFunction = failureRouteTrafficEvents;
        
        dispatch(beginFunction());

        let manager: IRouteNetworkManager = new RouteNetworkManager(apiUrl);

        manager.getTrafficEvents(buildLastResponse(getState()), { id: routeId, includeGeometry: true }, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message}))
                    else {
                        let reduxObject: IStoreProcessedResponse = response.getReduxObject();
    
                        let features: IFeature[] = response.data?.features;
                        let data: any[] = [];
                        let geometries: IGeometry[] = [];

                        features?.forEach( (feature: IFeature) => { 
                            geometries.push(feature.geometry); 
                            data.push(
                                {...feature.properties.data, 
                                    coord: {
                                        lat: feature.geometry.coordinates[1], 
                                        lng: feature.geometry.coordinates[0]
                                    },
                                    id: feature.properties.data?.id
                                }
                            ); 
                        });
                        
                        dispatch(successFunction({...reduxObject, data, geometries}))
                    }
                }
            ).catch(
                (error: Error) => dispatch(failureFunction({errorMessage: error.message}))
            );
    }
};

// retrieves test data for this data type
export const get_traffic_events_test = (routeId: string, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        alert("routes: get_traffic_events_test is not yet implemented.");
    }
};

// retrieves all data from api for this data type
export const get_traffic_events_head_api = (routeId: string, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        let beginFunction = beginRouteTrafficEvents;
        let successFunction = successRouteTrafficEvents;
        let failureFunction = failureRouteTrafficEvents;
        
        dispatch(beginFunction());

        let manager: IRouteNetworkManager = new RouteNetworkManager(apiUrl);

        manager.getTrafficEvents(buildLastResponse(getState()), { id: routeId, includeGeometry: true }, true)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message, trafficEventStatus: response.status}))
                    else {
                        dispatch(successFunction({...response.getReduxObject(), data: response.data}))
                    }
                }
            ).catch(
                (error: Error) => dispatch(failureFunction({errorMessage: error.message}))
            );
    }
};

// retrieves test data for this data type
export const get_traffic_events_head_test = (routeId: string, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        alert("routes: get_traffic_head_events_test is not yet implemented.");
    }
};

// handles dispatching a get cameras request from either api or test source based on args
export const getCameras = (routeId: string, mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["route"]].camerasLoading) return;

        if (test)
            dispatch(get_cameras_test(routeId, mode));
        else
            dispatch(get_cameras_api(routeId, mode));
    }
}

// retrieves all data from api for this data type
export const get_cameras_api = (routeId: string, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        let beginFunction = beginRouteCameras;
        let successFunction = successRouteCameras;
        let failureFunction = failureRouteCameras;
        
        dispatch(beginFunction());

        let manager: IRouteNetworkManager = new RouteNetworkManager(cameraApiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.getCameras(buildLastResponse(getState()), { id: routeId, includeGeometry: false }, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else {
                            let features: IFeature[] = response.data?.features;
                            let data: any[] = [];
                            let geometries: IGeometry[] = [];
    
                            features?.forEach( (feature: IFeature) => { 
                                geometries.push(feature.geometry); 
                                data.push(
                                    {...feature.properties, 
                                        coord: {
                                            lat: feature.geometry.coordinates[1], 
                                            lng: feature.geometry.coordinates[0]
                                        },
                                        id: feature.properties.id
                                    }
                                ); 
                            });
                            
                            dispatch(successFunction( { ...response.getReduxObject(), data: response.data} ))
                        }
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({errorMessage: error.message}))
                );
            }
        ).catch(
            (err: any) => {
                console.error("Error retrieving user access token.");
                manager.getCameras(buildLastResponse(getState()), { id: routeId, includeGeometry: false }, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else {
                            dispatch(successFunction( { ...response.getReduxObject(), data: response.data} ))
                        }
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({errorMessage: error.message}))
                );
            }
        )
    }
};

// retrieves test data for this data type
export const get_cameras_test = (routeId: string, mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = beginRouteCameras;
        let successFunction = successRouteCameras;

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const routeReducer = routeSlice.reducer;