// libraries
import styled from "styled-components";
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS } from "~/constants";
import { whenSmallMax } from "~/theme";

export const StyledModal = styled.div`
    position: relative;
    width: 415px;
    padding: 20px;
    box-sizing: border-box;

    border-radius: ${BORDER_RADIUS}px;

    ${flexSmart("flex-start center")}
    flex-direction: column;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    ${whenSmallMax(`
        width: 100%;
        height: 100%;

        max-width: 100%;
        max-height: 100%;

        border-radius: 0;
    `)}
`;

export const StyledButton = styled.div<{cancel?: boolean, disabled?: boolean}>`
    ${flexSmart("center center")}

    width: ${props => props.cancel ? "110px" : "215px"};
    height: 46px;
    padding: 10px;
    font-size: 24px;
    font-weight: 400;

    background-color: ${props => props.cancel ? "#F2F2F7" : props.theme.colors["primary"].rgba};

    color: ${props => props.theme.colors["text-secondary"].rgba};

    border: none;
    border-radius: ${BORDER_RADIUS}px;

    ${props => props.disabled ? "opacity: 0.5" : ""};

    :hover {
        cursor: ${props => props.disabled ? 'none' : 'pointer'};
    }
`;

export const ButtonSection = styled.div`
    ${flexSmart("center center")}
    gap: 10px;
`;

export const TextSection = styled.div`
    ${flexSmart("center center")}
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 20px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const BoldText = styled.div`
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
`;

export const Text = styled.div`
    font-weight: 400;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
`;