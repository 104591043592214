// libraries
import React, { Fragment } from "react";
// hooks & context
import { ThemeContext } from "../../../theme";
// styles
import * as SC from "./Styled";
// components
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
import SiteNavContext from "~/navigation/SiteNavContext";

export type IProps = {
    callback?: () => void;
};

export const AddVideoboardButton: React.FC<IProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);
    const navContext: any = React.useContext(SiteNavContext);
    const userStore = useSelector( (state: any) => state.users);
    const [ addVideoboard, setAddVideoboard ] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (userStore.profile){
            let profile: AAUserAuthorizationProfile = new AAUserAuthorizationProfile(userStore.profile);
            setAddVideoboard(profile.hasPrivilege("CreateVideoboard"));
        }
    }, [userStore.profile]);

    return (
        <div>
            {
                addVideoboard &&
                <SC.StyledButton onClick={() => {
                        navContext.setSelectedSiteLink([1,0]); 
                        navContext.setSelectedRoute("/videoboards/editor/");
                    }}>
            
                    <Fragment>
                        <FontAwesomeIcon
                            className="fa-fw"
                            icon={faCirclePlus}
                            size={"1x"}
                            color={theme.colors["text-secondary"].rgba}
                        />
                        {"Add Videoboard"}
                    </Fragment>

                </SC.StyledButton>
            }
        </div>
        
    );
};

export default AddVideoboardButton;