// libraries
import { getById as getVideoboardById } from "~/store/algo-api/slices/videoboards";
// hooks & context
import { useEffect } from "react";
import { ATVideoBoard } from "@algo/network-manager/models/v4";
import React from "react";
import { useDispatch } from "react-redux";

export const useEditedVideoboard = (isEdit: boolean, videoboardData: ATVideoBoard | undefined) => {
    const [editedVideoboard, setEditedVideoboard] = React.useState<ATVideoBoard>();

    useEffect(() => {
        if (isEdit && videoboardData) {
            setEditedVideoboard(videoboardData);
        } else {
            setEditedVideoboard(getNewVideoboard);
        }
    }, [videoboardData, isEdit]);

    return [editedVideoboard, setEditedVideoboard] as const;
};

const getNewVideoboard = (): any => {
    const newVideoboard = new ATVideoBoard({
        id: 0,
        name: "",
        pages: [
            {
                id: 0,
                rowCount: 1,
                columnCount: 1,
                placement: 0,
                items: Array.from({ length: 1 }, () => ({
                    id: 0,
                    placement: 0,
                    camera: null,
                })),
            },
        ],
        pageOnSeconds: 60,
    });
    return newVideoboard;
}

export const useGetVideoboard = (isEdit: boolean, numericId: number) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch data only if in edit mode
        if (isEdit) {
            dispatch(getVideoboardById(numericId));
        }
    }, [isEdit, numericId]);
};