// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import * as PageStyles from "~/components/views/page-template/Styled";
// component
import { useDropdownData, useFilterContent, useLoadCameras } from "../cameras/hooks";
import EditVideoboardSnapshotSection from "./EditVideoboardSnapshotSection";
import { EATPlaceType, IATCityDto, IATCountyDto, IATPlaceDto } from "@algo/network-manager/models/v4";
import { AlgoDropdown } from "~/components/views/algo-dropdown/AlgoDropdown";
import { getEnumStrings } from "@caps-mobile/common-lib";
import { EATRegion } from "@algo/network-manager/models/v4";
import { dePascal } from "~/library/string-tools";

// constants
const REGIONS: string[] = getEnumStrings(EATRegion).filter(x => x !== 'Unknown').map((str: string) => str !== "ALDOT" ? dePascal(str) : str);

export type IProps = {
    searchText: string,
    handleDragStart: (event: React.DragEvent<HTMLDivElement>, cameraId: number | undefined) => void;
};

export const CameraSection: React.FC<IProps> = React.memo((props) => {

    const {
        searchText, handleDragStart
    } = props;

    const cameraStore: any = useLoadCameras();
    const filteredCameras = cameraStore.dataFilteredBySelections;
    const [cityStore, countyStore, placeStore] = useDropdownData();

    // Dropdown Indices State
    const [cityIndices, setCityIndices] = React.useState<number[]>([]);
    const [countyIndices, setCountyIndices] = React.useState<number[]>([]);
    const [roadwayIndices, setRoadwayIndices] = React.useState<number[]>([]);
    const [regionIndices, setRegionIndices] = React.useState<number[]>([]);

    // Filter Check
    const noFiltersOrSearch = cityIndices.length === 0 && countyIndices.length === 0 &&
        roadwayIndices.length === 0 && regionIndices.length === 0 &&
        searchText === "";

    // City and County Names for Dropdowns
    const cityStrings = cityStore.data?.map((city: IATCityDto) => city.name || "??");
    const countyStrings = countyStore.data?.map((county: IATCountyDto) => county.name || "??");
    const roadwayStrings = placeStore.data?.filter((place: IATPlaceDto) =>
        place.type !== EATPlaceType.Arterial &&
        place.type !== EATPlaceType.Unknown &&
        place.type !== EATPlaceType.ExitRamp &&
        place.type !== EATPlaceType.MilePost
    ).map((place: IATPlaceDto) => place.name || "??");

    // Content Filtering
    useFilterContent(
        searchText, cityIndices, countyIndices, roadwayIndices, regionIndices,
        cameraStore,cityStrings, countyStrings, roadwayStrings, REGIONS
    );

    // Dropdown Change Callbacks
    const cityDropdownChange = React.useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, cityIndices, setCityIndices);
        }, [cityIndices, setCityIndices]
    );

    const countyDropdownChange = React.useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, countyIndices, setCountyIndices);
        }, [countyIndices, setCountyIndices]
    );

    const roadwayDropdownChange = React.useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, roadwayIndices, setRoadwayIndices);
        }, [roadwayIndices, setRoadwayIndices]
    );

    const regionDropdownChange = React.useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, regionIndices, setRegionIndices);
        }, [regionIndices, setRegionIndices]
    );

    return (
        <SC.CameraSectionWrapper>

            <PageStyles.FilterRow>

                <AlgoDropdown
                    options={cityStrings}
                    placeholder={"All Cities"}
                    showPlaceholder={cityIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={cityDropdownChange}
                />

                <AlgoDropdown
                    options={countyStrings}
                    placeholder={"All Counties"}
                    showPlaceholder={countyIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={countyDropdownChange}
                />

                <AlgoDropdown
                    options={roadwayStrings}
                    placeholder={"All Roadways"}
                    showPlaceholder={roadwayIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={roadwayDropdownChange}
                />

                <AlgoDropdown
                    options={REGIONS}
                    placeholder={"All Regions"}
                    showPlaceholder={regionIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={regionDropdownChange}
                />

            </PageStyles.FilterRow>

            <SC.CameraWrapper>
                {filteredCameras && filteredCameras.length > 0 && !noFiltersOrSearch &&
                    <EditVideoboardSnapshotSection objectList={filteredCameras} onDragStart={handleDragStart} />
                }

                {noFiltersOrSearch &&
                    <SC.NoCameras>Enter search criteria to see available cameras</SC.NoCameras>
                }

                {(!filteredCameras || !filteredCameras?.length || filteredCameras?.length <= 0) && !noFiltersOrSearch &&
                    <SC.NoCameras>No Cameras</SC.NoCameras>
                }
            </SC.CameraWrapper>

        </SC.CameraSectionWrapper>
    );
});

export default CameraSection;

const commonDropdownSelectLogic = (
    selectedIndex: number,
    indicesList: number[],
    stateUpdateCallback: (newArray: number[]) => void
) => {
    if (selectedIndex === -1) {
        stateUpdateCallback([]);
    }
    else {
        let curIndex: number = indicesList.indexOf(selectedIndex);
        let newIndices: number[] = indicesList;
        if (curIndex === -1) {
            newIndices.push(selectedIndex);
        }
        else {
            newIndices.splice(curIndex, 1);
        }
        stateUpdateCallback([...newIndices]);
    }
};
