// libraries
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS, NAV_WIDTH_MINI, NAV_WIDTH_OPEN } from "~/constants";

export type IPanel = {
    panelOpen?: boolean;
}

export const StyledNavPanel = styled.div<IPanel>`
    width: ${NAV_WIDTH_OPEN}px;
    height: 100%;

    padding: 0px 20px 5px 20px;

    box-sizing: border-box;

    opacity: 1;

    ${props => props.panelOpen 
        ? `` 
        : `
            width: ${NAV_WIDTH_MINI}px;
        `
    }

    background-color: rgba(117, 117, 117, 0.1);

    transition: width .5s ease-out, opacity ${ props => props.panelOpen ? "1.5s" : ".0s" } .25s;

    display: flex;
    flex-direction: column;
`

export const LogoRow = styled.div<IPanel>`
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;

    ${props => props.panelOpen 
        ?   `justify-content: space-between;`
        :   `justify-content: center;`
    }
    

`;

export const LogoWrap = styled.div`
    padding-top: 5px;
    height: 36px; 
    ${flexSmart("flex-start center")}
`;

export const ToggleWrapper = styled.div`
    height: 100%;
    font-size: 18px;

    display: flex;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

export const NavTitle = styled.div<IPanel>`
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;

    ${props => props.panelOpen ? `display: none;` : ""}

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const NavContentWrapper = styled.div<IPanel>`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow: hidden;
    padding-top: 20px;
    position: relative;
    box-sizing: border-box;
`;

export const Content = styled.div` 
    flex: 1;
    overflow-y: auto;
    padding-bottom: 3rem;

    ${RemoveScrollbar}
`;

export const FadeEffect = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3rem;
    pointer-events: none;
    background-image: 
        ${props => 
            `linear-gradient(to bottom, 
            ${props.theme.colors["bg-navbar-0"].rgba} 0%, 
            ${props.theme.colors["bg-navbar-0.1"].rgba} 10%,
            ${props.theme.colors["bg-navbar-1"].rgba} 100%)`};
    z-index: 1;
`;

export const StyledNavSection = styled.div<{panelOpen?: boolean}>`
    padding-bottom: 20px;
    box-sizing: border-box;

    ${props => props.panelOpen ? `` : `padding-bottom: 0px;`}
`;

export const SectionDivider = styled.div`
    width: 100%;
    height: 2px;

    background-color: #8E8E93;

    border: none;
    border-radius: ${BORDER_RADIUS}px;
`;

export const AuthenticationState = styled.div<{signedIn?: boolean, loggedIn?: boolean}>`
    cursor: ${props => props.loggedIn ? `default` : `pointer`};
    border: 2px solid ${props => props.theme.colors["primary"].rgba};
    border-radius: 12px;
    ${flexSmart("flex-start center")}
    ${props => props.signedIn ? `background-color: rgba(4,149,101,0.09)` : ``};
    gap: 8px;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;
`;

export const AuthenticationStateText = styled.div<{signedIn?: boolean}>`
    color: ${props => props.theme.colors["text-primary"].rgba};
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;        
    overflow: hidden;           
    text-overflow: ellipsis;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100px;
`;

export const AuthenticationStateIcon = styled.div<{signedIn?: boolean}>`
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const AuthenticationStateLogout = styled.div<{signedIn?: boolean}>`
    color: rgba(255, 59, 48, 1);
    font-size: 14px;
    font-weight: 700;
    margin-left: auto;
    white-space: nowrap;
    cursor: pointer
`;

export const AuthenticationStateWrapper = styled.div<{signedIn?: boolean}>`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow: hidden;
`;