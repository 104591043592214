// libraries
import * as React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// styles
import * as SC from "./Styled";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WatchTimer from "../watch-timer.ts/WatchTimer";
import { IATVideoPageDto } from "@algo/network-manager/models/v4";

export type IProps = {
    playCallback: () => void;
    pages: IATVideoPageDto[];
    activePageIndex: number;
    pageSelectCallback: (newIndex: number) => void;
    streamTimeout: number;
    timeoutCallback: () => void;
    watchTimerKey: number;
};

export const VBLowerUI: React.FC<IProps> = (props) => {

    const { 
        playCallback,
        pages, activePageIndex = 0,
        pageSelectCallback,
        streamTimeout, timeoutCallback,
        watchTimerKey
    } = props;

    //const testArray = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    //const testArray = [{}, {}, {}, {}, {}, {}, {}, {}];

    const [ playing, setPlaying ] = React.useState<boolean>(true);

    return (
        <SC.LowerUI>                    
            <SC.ControlsRow>

                <SC.UIPrevious onClick={() => { setPlaying(true); pageSelectCallback(decrementPage(activePageIndex, pages)); }}>
                    <SC.ArrowButton>
                        <SC.BackdropWrap isDisabled={activePageIndex === 0}>
                            <FontAwesomeIcon icon={solid("arrow-left")} />
                        </SC.BackdropWrap>
                    </SC.ArrowButton>
                    <SC.UICaption>{`Previous Page`}</SC.UICaption>
                </SC.UIPrevious>

                <SC.UITogglePlay onClick={() => { setPlaying(!playing); playCallback(); }}>
                    {   (playing)
                        ? <FontAwesomeIcon icon={solid("pause")} />
                        : <FontAwesomeIcon icon={solid("play")} />
                    }
                    <SC.UICaption>{playing ? `Pause` : `Play`}</SC.UICaption>
                </SC.UITogglePlay>

                <SC.UINext onClick={() => { setPlaying(true); pageSelectCallback(incrementPage(activePageIndex, pages)); }}>
                    <SC.ArrowButton>
                        <SC.BackdropWrap isDisabled={activePageIndex === ( pages.length - 1 )}>
                            <FontAwesomeIcon icon={solid("arrow-right")} />
                        </SC.BackdropWrap>
                    </SC.ArrowButton>
                    <SC.UICaption>{`Next Page`}</SC.UICaption>
                </SC.UINext>

            </SC.ControlsRow>

            <SC.ProgressBarRow>
                
                <WatchTimer key={watchTimerKey} playing={playing}
                    streamTimeout={streamTimeout} timeoutCallback={timeoutCallback} />
                    
            </SC.ProgressBarRow>

            <SC.PagesRow>
                {   pages && pages.map(
                    (page: any, mapIndex: number) => {
                        return (
                            <SC.PageBubble
                                key={"page-number-" + mapIndex}
                                isActive={mapIndex === activePageIndex}
                                onClick={() => { setPlaying(true); pageSelectCallback(mapIndex); }}
                            >
                                {mapIndex + 1}
                            </SC.PageBubble>
                        )
                    })
                }
            </SC.PagesRow>

        </SC.LowerUI>
    );
};

export default React.memo(VBLowerUI);

export const incrementPage = (activePageIndex: number, pages: any[]): number => {

    let newIndex: number = activePageIndex;

    if (newIndex < pages.length - 1){
        newIndex = newIndex + 1;
    }

    return newIndex;
};

export const decrementPage = (activePageIndex: number, pages: any[]): number => {

    let newIndex: number = activePageIndex;

    if (newIndex > 0){
        newIndex = newIndex - 1;
    }

    return newIndex;
};