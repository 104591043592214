import { colors as bsColors } from "./colors-bs";

export const ColorsLight = {
    ...bsColors,
    "disabled": {
        "hex": "E9ECEF",
        "hex-string": "#E9ECEF",
        "rgb": {
            "red": "233",
            "green": "236",
            "blue": "239"
        },
        "rgba": "rgba(233, 236, 239, 1)",
        "alpha": 1.0
    },
    "text-primary": {
        "hex": "000000",
        "hex-string": "#000000",
        "rgb": {
            "red": "0",
            "green": "0",
            "blue": "0"
        },
        "rgba": "rgba(0,0,0,0.75)",
        "alpha": 0.75
    },
    "text-secondary": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": 255,
            "green": 255,
            "blue": 255
        },
        "rgba": "rgba(255,255,255,1)",
        "alpha": 1.0
    },
    "text-disabled": {
        "hex": "A9A9AC",
        "hex-string": "#A9A9AC",
        "rgb": {
            "red": "169",
            "green": "169",
            "blue": "172"
        },
        "rgba": "rgba(169, 169, 172, 1)",
        "alpha": 1.0
    },
    "text-input": {
        "hex": "495057",
        "hex-string": "#495057",
        "rgb": {
            "red": "73",
            "green": "80",
            "blue": "87"
        },
        "rgba": "rgba(73, 80, 87, 1)",
        "alpha": 1.0
    },
    "input-background": {
        "hex": "F2F2F7",
        "hex-string": "#F2F2F7",
        "rgb": {
            "red": 242,
            "green": 242,
            "blue": 247
        },
        "rgba": "rgba(242,242,247,1)",
        "alpha": 1.0
    },
    "bg-page": {
        "hex": "F4F4F4",
        "hex-string": "#F4F4F4",
        "rgb": {
            "red": 244,
            "green": 244,
            "blue": 244
        },
        "rgba": "rgba(244,244,244,1)",
        "alpha": 1.0
    },
    "bg-primary": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": 255,
            "green": 255,
            "blue": 255
        },
        "rgba": "rgba(255,255,255,1)",
        "alpha": 1.0
    },
    "bg-secondary": {
        "hex": "B7B7C1",
        "hex-string": "#B7B7C1",
        "rgb": {
            "red": 183,
            "green": 183,
            "blue": 193
        },
        "rgba": "rgba(183,183,193,1)",
        "alpha": 1.0
    },
    "bg-navbar-1": {
        "hex": "f1f1f1",
        "hex-string": "#f1f1f1",
        "rgb": {
            "red": 241,
            "green": 241,
            "blue": 241
        },
        "rgba": "rgba(241,241,241,1)",
        "alpha": 1.0
    },
    "bg-navbar-0.1": {
        "hex": "f1f1f1",
        "hex-string": "#f1f1f1",
        "rgb": {
            "red": 241,
            "green": 241,
            "blue": 241
        },
        "rgba": "rgba(241,241,241,0.1)",
        "alpha": 0.1
    },
    "bg-navbar-0": {
        "hex": "f1f1f1",
        "hex-string": "#f1f1f1",
        "rgb": {
            "red": 241,
            "green": 241,
            "blue": 241
        },
        "rgba": "rgba(241,241,241,0)",
        "alpha": 0
    },
    "bg-grey": {
        "hex": "F2F2F7",
        "hex-string": "#F2F2F7",
        "rgb": {
            "red": 242,
            "green": 242,
            "blue": 247
        },
        "rgba": "rgba(242,242,247,1)",
        "alpha": 1.0
    },
    "bg-disabled": {
        "hex": "E9ECEF",
        "hex-string": "#E9ECEF",
        "rgb": {
            "red": "233",
            "green": "236",
            "blue": "239"
        },
        "rgba": "rgba(233, 236, 239, 1)",
        "alpha": 1.0
    },
    "bg-highlight": {
        "hex": "B7B7C1",
        "hex-string": "#B7B7C1",
        "rgb": {
            "red": 183,
            "green": 183,
            "blue": 193
        },
        "rgba": "rgba(183,183,193,1)",
        "alpha": 1.0
    },
    "drop-shadow": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": "255",
            "green": "255",
            "blue": "255"
        },
        "rgba": "rgba(255, 255, 255, 0)",
        "alpha": 0
    },
    "primary": {
        "hex": "007556",
        "hex-string": "#007556",
        "rgb": {
            "red": 0,
            "green": 117,
            "blue": 86
        },
        "rgba": "rgba(0, 117, 86, 1)",
        "alpha": 1.0
    },
    "primary-25": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 0.1)",
        "alpha": 0.1
    },
    "primary-50": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 0.5)",
        "alpha": 0.5
    },
    "primary-75": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 0.75)",
        "alpha": 0.75
    },
    "secondary": {
        "hex": "",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0,0,0,1)",
        "alpha": 1.0
    },
    "algo-green": {
        "hex": "005338",
        "hex-string": "#005338",
        "rgb": {
            "red": 0,
            "green": 83,
            "blue": 56
        },
        "rgba": "rgba(0,83,56, 1)",
        "alpha": 1.0
    },
    "bg-menu": {
        "hex": "FFFFFF",
        "hex-string": "#FFFFFF",
        "rgb": {
            "red": 255,
            "green": 255,
            "blue": 255
        },
        "rgba": "rgba(255,255,255,1)",
        "alpha": 1.0
    },
    "bg-menu-link-highlight": {
        "hex": "",
        "hex-string": "",
        "rgb": {
            "red": 61,
            "green": 61,
            "blue": 68
        },
        "rgba": "rgba(61, 61, 68, 0.1)"
    },
    "border-primary": {
        "hex": "1e824c",
        "hex-string": "#1e824c",
        "rgb": {
            "red": 30,
            "green": 130,
            "blue": 76
        },
        "rgba": "rgba(30, 130, 76, 1)",
        "alpha": 1
    },
    "border-secondary": {
        "hex": "000000",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0, 0, 0, 0.25)",
        "alpha": 0.5
    },
    "border-disabled": {
        "hex": "CED4DA",
        "hex-string": "#CED4DA",
        "rgb": {
            "red": "206",
            "green": "212",
            "blue": "218"
        },
        "rgba": "rgba(206, 212, 218, 1)",
        "alpha": 1.0
    },
    "border-primary-old": {
        "hex": "1e824c",
        "hex-string": "#1e824c",
        "rgb": {
            "red": "30",
            "green": "130",
            "blue": "76"
        },
        "rgba": "rgba(74, 213, 138, 1)",
        "alpha": 1.0
    },
    // "scrollbar-bg": {
    //     "hex": "FFFFFF",
    //     "hex-string": "#FFFFFF",
    //     "rgb": {
    //         "red": 255,
    //         "green": 255,
    //         "blue": 255
    //     },
    //     "rgba": "rgba(255,255,255,1)",
    //     "alpha": 1.0
    // },
    "scrollbar-bg": {
        "hex": "efefef",
        "hex-string": "#efefef",
        "rgb": {
            "red": 239,
            "green": 239,
            "blue": 239
        },
        "rgba": "rgba(239,239,239,1)",
        "alpha": 1.0
    },
    // "scrollbar-thumb": {
    //     "hex": "005338",
    //     "hex-string": "#005338",
    //     "rgb": {
    //         "red": 0,
    //         "green": 83,
    //         "blue": 56
    //     },
    //     "rgba": "rgba(0,83,56, .75)",
    //     "alpha": 0.75
    // },
    "scrollbar-thumb": {
        "hex": "000000",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0,0,0, 0.5)",
        "alpha": 0.5
    },
    // "scrollbar-thumb-hover": {
    //     "hex": "005338",
    //     "hex-string": "#005338",
    //     "rgb": {
    //         "red": 0,
    //         "green": 83,
    //         "blue": 56
    //     },
    //     "rgba": "rgba(0,83,56, 1)",
    //     "alpha": 1
    // },
    "scrollbar-thumb-hover": {
        "hex": "000000",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0,0,0, 0.75)",
        "alpha": 0.75
    },
    "hover": {
        "hex": "F2F2F7",
        "hex-string": "#F2F2F7",
        "rgb": {
            "red": 242,
            "green": 242,
            "blue": 247
        },
        "rgba": "rgba(242, 242, 247, 1)",
        "alpha": 1.0
    },
    "divider": {
        "hex": "E5E5EA",
        "hex-string": "#E5E5EA",
        "rgb": {
            "red": 229,
            "green": 229,
            "blue": 234
        },
        "rgba": "rgba(229, 229, 234, 1)",
        "alpha": 1.0
    },
    toolbox: {
        "hex": "000000",
        "hex-string": "#000000",
        "rgb": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "rgba": "rgba(0,0,0,1)",
        "alpha": 1.0
    }
};

export default ColorsLight;