// libraries
import * as React from "react";
// contexts
import { ThemeContext } from "styled-components";
// styles
import * as SC from "./Styled";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export type IProps = {
    //
};

export const EditorUnavaialble: React.FC<IProps> = (props) => {

    const navigate = useNavigate();
    const theme: any = React.useContext(ThemeContext);

    return (
        <SC.StyledWrapper>
            <SC.UnavailableWrapper>

                <FontAwesomeIcon
                    className="fa-fw"
                    icon={regular("presentation-screen")}
                    size={"5x"}
                    color={theme.colors["primary"].rgba}
                />

                <SC.EditorText>
                    {"Videoboard Editor Unavailable"}
                </SC.EditorText>

                <SC.NoticeText>
                    {"Please visit this page on a desktop or tablet web browser."}
                </SC.NoticeText>

                <SC.StyledReturnButton
                    onClick={() => navigate("/videoboards")}
                >
                    {"Return to Videoboards"}
                </SC.StyledReturnButton>

            </SC.UnavailableWrapper>
        </SC.StyledWrapper>
    );
};

export default EditorUnavaialble;