// libraries
import * as React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// styles
import * as SC from "./Styled";
// components
import { NavPanelSection } from "./nav-panel-section/NavPanelSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// context
import { ThemeContext } from "~/theme";
import SiteNavContext from "~/navigation/SiteNavContext";
import { useAuth } from "react-oidc-context";
// constants
import { navPanelMap } from "~/navigation/navigation-map";
import { ANON_SECTION } from "~/string-literals";
import Logo from "../../components/views/logo/Logo";
import Accordion from "../../components/views/accordion/Accordion";
import { faRightToBracket, faUser } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "~/store/algo-api/slices/users";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";

export type IProps = {
    //
};

export const NavPanel: React.FC<IProps> = (props) => {

    const dispatch = useDispatch();
    const theme: any = React.useContext(ThemeContext);
    const navContext: any = React.useContext(SiteNavContext);
    const userStore = useSelector( (state: any) => state.users);
    const [ viewVideoboard, setViewVideoboard ] = React.useState<boolean>(false);

    const auth = useAuth();
    const userNameLabel: string = 
        (auth.isAuthenticated)
            ? (auth.user?.profile.preferred_username)
                ? auth.user?.profile.preferred_username
                : "Unknown UserName"
            : `ALGO Authentication Login`

    const handleClickLogin = () => {
        if (auth.isAuthenticated){
            auth.signoutRedirect();
        }
        else {
            auth.signinRedirect();
        }
    };

    React.useEffect(() => {
        dispatch(getProfile() as any);
    }, [auth.isAuthenticated]);

    React.useEffect(() => {
        if (userStore.profile){
            let profile: AAUserAuthorizationProfile = new AAUserAuthorizationProfile(userStore.profile);
            setViewVideoboard(profile.hasPrivilege("ViewVideoboard"));
        }
    }, [userStore.profile]);
    
    return (
        <SC.StyledNavPanel panelOpen={navContext.panelOpen}>

            <SC.LogoRow panelOpen={navContext.panelOpen}>

                {   navContext.panelOpen &&
                    <SC.LogoWrap>
                        <Logo />
                    </SC.LogoWrap>
                }
                
                <SC.ToggleWrapper
                    onClick={() => navContext.togglePanelOpen()}
                >
                    { navContext.panelOpen
                        ? <FontAwesomeIcon
                            icon={solid("x")}
                            color={theme.colors["primary"].rgba}
                        />
                        : <FontAwesomeIcon
                            icon={solid("bars")}
                            color={theme.colors["primary"].rgba} 
                        />
                    }
                </SC.ToggleWrapper>

            </SC.LogoRow>

            {/* Render the title of the nav panel, if it exists */}
            {   navPanelMap.title && 
                <SC.NavTitle panelOpen={navContext.panelOpen}>
                    {navPanelMap.title}
                </SC.NavTitle> 
            }

            <SC.NavContentWrapper panelOpen={navContext.panelOpen}>
                <SC.Content>
                    {/* Render each of the nav sections, if any exist */}
                    {   navPanelMap && navPanelMap.navSections && navPanelMap.navSections.map(
                            (navSection: any, mapIndex: number) => {
                                if(navSection.title === "Video" && !viewVideoboard) return;
                                return (
                                    navContext.panelOpen 
                                        ?   <SC.StyledNavSection 
                                                key={`${navSection.title || ANON_SECTION}${mapIndex}`}
                                                panelOpen={navContext.panelOpen}
                                            >
                                                <Accordion title={navSection.title} reverseChevron={true}>
                                                    <NavPanelSection 
                                                        navSection={navSection}
                                                    />
                                                </Accordion>
                                            </SC.StyledNavSection>
                                        : 
                                        <SC.StyledNavSection 
                                            key={`${navSection.title || ANON_SECTION}${mapIndex}`}
                                            panelOpen={navContext.panelOpen}
                                        >
                                            { (mapIndex !== 0) && 
                                                <SC.SectionDivider />
                                            }
                                            <NavPanelSection 
                                                navSection={navSection}
                                            />
                                        </SC.StyledNavSection>
                                );
                            }
                        )
                    }
                </SC.Content>

                <SC.FadeEffect />

            </SC.NavContentWrapper>
            
            {navContext.panelOpen &&
                (!auth.isAuthenticated ? 
                <SC.AuthenticationState signedIn={auth.isAuthenticated} onClick={handleClickLogin}>
                    <SC.AuthenticationStateIcon>
                        <FontAwesomeIcon icon={faRightToBracket} color={theme.colors["primary"].rgba} /> 
                    </SC.AuthenticationStateIcon>
                    <SC.AuthenticationStateText>
                        Sign In
                    </SC.AuthenticationStateText>
                </SC.AuthenticationState>
                :
                <SC.AuthenticationState signedIn={auth.isAuthenticated} loggedIn={true}>
                    <SC.AuthenticationStateIcon>
                        <FontAwesomeIcon icon={faUser} color={theme.colors["primary"].rgba} /> 
                    </SC.AuthenticationStateIcon>
                    <SC.AuthenticationStateWrapper>
                        <SC.AuthenticationStateText>
                            {userNameLabel}
                        </SC.AuthenticationStateText>
                        <SC.AuthenticationStateLogout onClick={handleClickLogin}>
                            Log Out
                        </SC.AuthenticationStateLogout>
                    </SC.AuthenticationStateWrapper>
                </SC.AuthenticationState>
                )
            }

        </SC.StyledNavPanel>
    )
};

export default NavPanel;

