import { 
    ATCamera, ATVideoBoard, ATVideoItem, ATVideoPage, IATCamera, IATCameraDto, IATCameraGroupDto, 
    IATVideoBoard, IATVideoBoardDto, IATVideoItem, IATVideoItemDto, IATVideoPageDto 
} from "@algo/network-manager/models/v4";

export class VideoboardManager {

    videoboard: IATVideoBoard;
    playerMap: { [key: string]: any };

    constructor(initialObject: IATVideoBoardDto | IATCameraGroupDto | IATCameraDto[]){
        // if the initial object is already a videoboard, just build a format class object out of it
        if ((initialObject as any).name || (initialObject as any).pages) 
            this.videoboard = new ATVideoBoard(initialObject as IATVideoBoardDto);
        // else if the initial object has a cameras property, feed that list to converter
        else if ((initialObject as any).cameras) this.videoboard = 
            this.cameraListToVideoboard((initialObject as any).cameras);
        // else if the initial object is an array, convert it
        else if (Array.isArray(initialObject))
            this.videoboard = this.cameraListToVideoboard(initialObject);
        // else create default empty videoboard
        else this.videoboard = new ATVideoBoard();

        this.playerMap = {};
    }

    updatePlayerMap = (key: string, player: any) => {
        this.playerMap = { ...this.playerMap, [key]: player };
    }

    cameraListToVideoboard(cameraList: IATCameraDto[]): IATVideoBoard{
        let newVideoBoard: IATVideoBoard = new ATVideoBoard();
        //newVideoBoard.name = "";
        newVideoBoard.pages = [];

        // if the given list is uon or empty, just return the default videoboard class object
        if (!cameraList || cameraList.length === 0) {
            //newVideoBoard.name = "Untitled Videoboard";
            return newVideoBoard;
        }

        for (let i = 0; i < cameraList.length; i += 4){
            
            let cameraListChunk = cameraList.slice(i, i + 4);
            let nextPage = new ATVideoPage();

            for (let j = 0; j < cameraListChunk.length; j++){
                let nextItem: IATVideoItem = new ATVideoItem();
                let nextCamera: IATCamera = new ATCamera(cameraListChunk[j]);
                nextItem.camera = nextCamera;
                nextItem.placement = j;
                nextPage.items.push(nextItem);

                // // add this camera's route designator to the cobbled title of this videoboard
                // // if the name is not already longer than 100 characters
                // if (newVideoBoard.name!.length <= 100){
                //     newVideoBoard.name = (newVideoBoard.name!.length > 0) 
                //         ?   `${newVideoBoard.name}, ${nextCamera.location.displayRouteDesignator}`
                //         :   nextCamera.location.displayRouteDesignator;
                // }
                // // if 100 or more characters exist already and no ellipsis exists, add one
                // else if (newVideoBoard.name?.indexOf("...") === -1 ){
                //     newVideoBoard.name += "...";
                // }
            }

            nextPage.columnCount = cameraList.length === 1 ? 1 : 2;
            newVideoBoard.pages.push(nextPage);
        }

        return newVideoBoard;
    };

    getFirstPageOrNull(): IATVideoPageDto | null{
        if (this.videoboard.pages){
            return this.videoboard.pages[0];
        }
        else return null;
    }

    getPageByNumberOrNull(pageNumber: number = 0): IATVideoPageDto | null{
        if (this.videoboard.pages){
            let page: IATVideoPageDto | null = null;
            page = this.videoboard.pages[pageNumber];
            return page ? page : null;
        }
        else return null;
    }

    getFirstCameraOrNull(): IATCameraDto | null {
        let firstPage: IATVideoPageDto | null = this.getFirstPageOrNull();
        if (!firstPage) return null;
        let firstItem: IATVideoItemDto | null = firstPage.items[0];
        if (!firstItem) return null;
        return firstItem.camera || null;
    }
};

export default VideoboardManager;