// libraries
import * as React from "react";
import { saveVideoboard, updateVideoboard } from "~/store/algo-api/slices/videoboards";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Pager } from "@algo/pager";
import { faCirclePlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// styles
import PageTemplate from "~/components/views/page-template/PageTemplate";
import * as PageStyles from "~/components/views/page-template/Styled";
import * as SC from "./Styled";
// types & models
import { ATVideoBoard } from "@algo/network-manager/models/v4";
// component
import SaveVideoboardErrorContext from "~/contexts/SaveVideoboardErrorModalContext";
import SaveErrorModal from "~/components/views/modal/save-error-modal/SaveErrorModal";
import SiteNavContext from "~/navigation/SiteNavContext";
import EditorUnavaialble from "./EditorUnavailable";
// hooks
import { useEditedVideoboard, useGetVideoboard } from "./hooks";
import { useBreakpoint } from "~/library/useBreakpoint";
import VideoboardSection from "./VideoboardSection";
import CameraSection from "./CameraSection";

export type IProps = {
    //
};

export const EditVideoboardPage: React.FC<IProps> = (props) => {

    // Contexts
    const theme: any = React.useContext(ThemeContext);
    const navContext: any = React.useContext(SiteNavContext);
    const saveVideoboardErrorContext: any = React.useContext(SaveVideoboardErrorContext);
    const { sm: isSmallMax } = useBreakpoint();

    // Route Params
    const { id } = useParams();
    const numericId = Number(id);
    const isEdit = !isNaN(numericId);

    // Get Data
    useGetVideoboard(isEdit, numericId);

    // State Management
    const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(0);
    const videoboardStore = useSelector((state: any) => state.videoboard);
    const [videoboard, setVideoboard] = useEditedVideoboard(isEdit, videoboardStore.data);

    // Row and Column Count
    const rowCount = videoboard?.pages?.[currentPageIndex]?.rowCount || 0;
    const columnCount = videoboard?.pages?.[currentPageIndex]?.columnCount || 0;
    const pageLayout = GetLayout(rowCount, columnCount);

    // Title Change Handler
    const handleTitleChange = (newTitle: string) => {
        if (videoboard) {
            const newVideoboard: ATVideoBoard = new ATVideoBoard({
                ...videoboard,
                name: newTitle,
            });
            setVideoboard(newVideoboard);
        }
      };

      const handleTimeChange = (newTime: number) => {
        if(videoboard){
            const newVideoboard: ATVideoBoard = new ATVideoBoard({
                ...videoboard,
                pageOnSeconds: newTime,
            });
            setVideoboard(newVideoboard);
        }
      };

    const layoutChange = (layout: any) => {

        if(videoboard){
            const newVideoboard: ATVideoBoard = new ATVideoBoard({
                ...videoboard,
            });

            if(videoboard?.pages){
                newVideoboard.pages[currentPageIndex].resizePage(layout.rowCount, layout.columnCount);
            }
            setVideoboard(newVideoboard);
        }

    }

    // Search Text State
    const [searchText, setSearchText] = React.useState<string>("");

    // Effect for Save Videoboard Error Handling
    React.useEffect(() => {
        if (videoboardStore.saved === false) {
            saveVideoboardErrorContext.setModalContent(
                <SaveErrorModal doneCallback={() => saveVideoboardErrorContext.setShowModal(false)} />
            );
            saveVideoboardErrorContext.setShowModal(true);
        } else if (videoboardStore.data?.id) {
            navContext.setSelectedSiteLink([1, 0]);
            navContext.setSelectedRoute(`/videoboards/editor/${videoboardStore.data.id}`);
        }
    }, [videoboardStore.saved]);

    const dispatch = useDispatch();
    const onSave = (videoboard: ATVideoBoard | undefined, isEdit: boolean) => {

        if (videoboard) {
            if (isEdit) {
                dispatch(updateVideoboard(videoboard.id, videoboard));
            }
            else {
                dispatch(saveVideoboard(videoboard));
            }
        }
    };

    const addPage = () => {
        if(videoboard){
            const newVideoboard: ATVideoBoard = new ATVideoBoard({
                ...videoboard,
            });
            newVideoboard.addPage(rowCount, columnCount);
            setVideoboard(newVideoboard);
            setCurrentPageIndex(newVideoboard?.pages.length - 1);
        }
    }

    const removePage = () => {
        if(videoboard){
            const newVideoboard: ATVideoBoard = new ATVideoBoard({
                ...videoboard,
            });
            if(videoboard?.pages?.length > 1){
                newVideoboard.removePage(currentPageIndex);
                setVideoboard(newVideoboard); 
                if(currentPageIndex===newVideoboard?.pages.length)
                {
                    setCurrentPageIndex(newVideoboard?.pages.length - 1);
                }
            }
        }
    }

    // Handle camera drag and drop
    const [draggedItemId, setDraggedItemId] = React.useState<number | undefined>();

    // Handle when a drag starts
    const handleDragStart = React.useCallback((event: React.DragEvent<HTMLDivElement>, cameraId: number | undefined) => {
        event.persist();
        requestAnimationFrame(() => {
            setDraggedItemId(cameraId);
        });
    }, []);

    return (
        <SC.PageWrapper>
            { !isSmallMax && 
                <PageTemplate
                    title={videoboard?.name}
                    backButton={true}
                    backButtonCallback={() => {
                        navContext.setSelectedSiteLink([1, 0]);
                        navContext.setSelectedRoute("/videoboards");
                    }}
                    searchBar={{
                        autoSubmit: true,
                        submitCallback: (newText: string) =>
                            setSearchText(newText)
                    }}
                    edit={true}
                    onTitleChange={handleTitleChange}
                    timer={videoboard?.pageOnSeconds}
                    onTimeChange={handleTimeChange}
                    onSave={() => onSave(videoboard, isEdit)}
                    isSaving={videoboardStore.isSaving}
                    playVideoboard={videoboard}
                >
                    
                    <CameraSection searchText={searchText} handleDragStart={handleDragStart} />

                    <PageStyles.FilteredContentSection>
                        <SC.LayoutContainer>
                            
                            <SC.ButtonsWrapper>
                                <SC.PageLayoutWrapper>
                                    <SC.PageLayout>
                                        <SC.PageLayoutText>
                                            {"Page Layout"}
                                        </SC.PageLayoutText>
                                        <SC.PageLayoutOptions >
                                            <SC.PageLayoutSquare selected={pageLayout === Page_Layout.one} onClick={() => layoutChange(Page_Layout.one)}>
                                                <SC.Square selected={pageLayout === Page_Layout.one} width={"25px"} height={"25px"} />
                                            </SC.PageLayoutSquare>
                                            <SC.PageLayoutColumn selected={pageLayout === Page_Layout.two} onClick={() => layoutChange(Page_Layout.two)}>
                                                <SC.Square selected={pageLayout === Page_Layout.two} width={"10px"} height={"25px"} />
                                                <SC.Square selected={pageLayout === Page_Layout.two} width={"10px"} height={"25px"} />
                                            </SC.PageLayoutColumn>
                                            <SC.PageLayoutGrid selected={pageLayout === Page_Layout.four} onClick={() => layoutChange(Page_Layout.four)}>
                                                <SC.PageLayoutGridWrapper>
                                                    <SC.Square selected={pageLayout === Page_Layout.four} width={"10px"} height={"10px"} />
                                                    <SC.Square selected={pageLayout === Page_Layout.four} width={"10px"} height={"10px"} />
                                                    <SC.Square selected={pageLayout === Page_Layout.four} width={"10px"} height={"10px"} />
                                                    <SC.Square selected={pageLayout === Page_Layout.four} width={"10px"} height={"10px"} />
                                                </SC.PageLayoutGridWrapper>
                                            </SC.PageLayoutGrid>
                                        </SC.PageLayoutOptions>
                                    </SC.PageLayout>
                                </SC.PageLayoutWrapper>
                                <SC.PageButtonsWrapper>
                                    <SC.StyledButton backgroundColor="#FF3B30" disabled={videoboard?.pages?.length===1} color={"#FFFFFF"} onClick={() => {removePage()}}>
        
                                        <React.Fragment>
                                            <FontAwesomeIcon
                                                className="fa-fw"
                                                icon={faTrash}
                                                size={"xl"}
                                                color={"#FFFFFF"}
                                            />
                                            {"Remove Page"}
                                        </React.Fragment>
        
                                    </SC.StyledButton>
                                    <SC.StyledButton width={"116px"} onClick={() => {addPage()}}>
        
                                        <React.Fragment>
                                            <FontAwesomeIcon
                                                className="fa-fw"
                                                icon={faCirclePlus}
                                                size={"xl"}
                                                color={theme.colors["text-secondary"].rgba}
                                            />
                                            {"Add Page"}
                                        </React.Fragment>
        
                                    </SC.StyledButton>
                                </SC.PageButtonsWrapper>
                            </SC.ButtonsWrapper>
        
                            <VideoboardSection videoboard={videoboard} pageLayout={pageLayout} 
                                currentPageIndex={currentPageIndex} draggedItemId={draggedItemId} setVideoboard={setVideoboard} />

                        </SC.LayoutContainer>
                    </PageStyles.FilteredContentSection>
                    <SC.PagerWrapper>
                        <Pager
                                    pageIndex={currentPageIndex}
                                    pageCount={videoboard?.pages ? videoboard.pages.length : 1}
                                    updatePageIndex={setCurrentPageIndex}
                                    minimalNav={true} size={"xs"}
                                    ellipsisThreshold={5} ellipsisOffset={1}
                                    backgroundColor={theme.colors["bg-primary"].rgba}
                                    backgroundColorActive={theme.colors["primary"].rgba}
                                    color={theme.colors["text-primary"].rgba}
                                    colorActive={theme.colors["text-secondary"].rgba}
                                    borderStyle={`2px solid ${theme.colors["primary"].rgba}`}
                                    hoverBorderStyle={`2px solid ${theme.colors["primary"].rgba}`}
                                    borderRadius={"12px"}
                                />
                    </SC.PagerWrapper>
                </PageTemplate>
            }

            { isSmallMax && 
                <EditorUnavaialble/>
            }
        </SC.PageWrapper>
    );
};

export default EditVideoboardPage;


export const GetLayout = (
    rowCount: number, columnCount: number
): { rowCount: number, columnCount: number } | null => {

    if (rowCount === 1 && columnCount === 1) {
        return Page_Layout.one;
    }
    else if (rowCount === 1 && columnCount === 2) {
        return Page_Layout.two;
    }
    else if (rowCount === 2 && columnCount === 2) {
        return Page_Layout.four;
    }
    else {
        return null;
    }
}

export const Page_Layout: { [key: string]: { rowCount: number, columnCount: number, cameraCount: number } } = {
    "one": { rowCount: 1, columnCount: 1, cameraCount: 1 },
    "two": { rowCount: 1, columnCount: 2, cameraCount: 2 },
    "four": { rowCount: 2, columnCount: 2, cameraCount: 4 }
};