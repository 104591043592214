// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { EAlgoApiObjectType, ICoord } from "~/interfaces";
// components
import ImgWithDefault from "../../image-with-default/ImgWithDefault";
// resources
import noLocationData from "~/resources/ui/graphics/no-location-data.png";
// constants
import { MAP_IMG_HEIGHT, NORMAL_DAY_MAP_IMAGE_SCHEME, NORMAL_NIGHT_MAP_IMAGE_SCHEME, SATELLITE_MAP_IMAGE_SCHEME } from "~/constants";
import { useBreakpoint } from "~/library/useBreakpoint";
// hooks and contexts
import { EThemeOptions, ThemeContext } from "~/theme";
import { useSelector } from "react-redux";
import { EATRegion } from "@algo/network-manager/models/v3";

declare var __API_URL__: string;

export type IProps = {
    coords: ICoord;
    zoom?: number;
    width?: number;
    height?: number;
    id?: number | string;
    type?: EAlgoApiObjectType;
    region?: EATRegion;
};

export const ExtendedObjectDetails: React.FC<IProps> = (props) => {

    const { 
        id, type, region,
        zoom = 14.5, 
        height = MAP_IMG_HEIGHT, 
        width = 550
    } = props;

    const cameraWidth: number = 960;
    const { md: isMedMax } = useBreakpoint();

    const mapLayersStore: any = useSelector( (state: any) => state["map-layers"]);
    const theme: any = React.useContext(ThemeContext);
    const [scheme, setScheme] = React.useState<string>("");

    React.useEffect(() => {
        const mapScheme: any = mapLayersStore.mapScheme
            ? (mapLayersStore.mapScheme.includes("satellite"))
            ? SATELLITE_MAP_IMAGE_SCHEME 
            :(theme.mode === EThemeOptions.Dark)
            ? NORMAL_NIGHT_MAP_IMAGE_SCHEME
            : NORMAL_DAY_MAP_IMAGE_SCHEME
            : NORMAL_DAY_MAP_IMAGE_SCHEME;
        setScheme(mapScheme);
    }, [theme]);
    

    // build the mapview api url for the static map image section
    const imgSrc = `${__API_URL__}/${mapTypeToApiEndpoint(type)}/${type === EAlgoApiObjectType["service-assistance-patrol"] 
        ? region : id?.toString()}/map@1x.jpg?width=${
        (type === EAlgoApiObjectType.camera || type === EAlgoApiObjectType["camera-group"]) ? 
        cameraWidth : isMedMax ? 350 : width}&height=${height}&zoom=${zoom}&scheme=${scheme}`;

    return (
        <SC.StyledMapImageWrap height={height} isCamera={(type === EAlgoApiObjectType.camera || type === EAlgoApiObjectType["camera-group"])}>
            { scheme !== "" &&
                <ImgWithDefault src={imgSrc} height={height.toString() + "px"} fallbackSrc={noLocationData}/>
            }
        </SC.StyledMapImageWrap>
    );
};

export default ExtendedObjectDetails;

export const mapTypeToApiEndpoint = (type?: EAlgoApiObjectType) => {

    switch(type){
        case EAlgoApiObjectType.camera:
        case EAlgoApiObjectType["camera-group"]:
            return "v4.0/Cameras";
        case EAlgoApiObjectType["message-sign"]:
            return "v3.0/MessageSigns";
        case EAlgoApiObjectType["state-facility"]:
            return "v3.0/Facilities";
        case EAlgoApiObjectType.ferry:
            return "v4.0/Ferries";
        case EAlgoApiObjectType["service-assistance-patrol"]:
            return "v4.0/ServiceAssistancePatrols";
        case EAlgoApiObjectType.crash:
        case EAlgoApiObjectType.incident:
        case EAlgoApiObjectType["regional-event"]:
        case EAlgoApiObjectType["road-condition"]:
        case EAlgoApiObjectType.roadwork:
        default: 
            return "v3.0/TrafficEvents";
    }
}