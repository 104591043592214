// libraries
import styled from "styled-components";
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import { FixedSizeList as List } from "react-window";
import { BORDER_RADIUS, SS_HEIGHT, SS_WIDTH } from "~/constants";
import { whenLargeMax } from "~/theme";

export const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
`;

export const ObjectsDiv = styled.div`
    flex: 1;
    height: 100px;
`;

export const StyledList = styled(List)`
    ${RemoveScrollbar}
`;

export const ArrowButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.8;
    }
`;

export const NoCameras = styled.div`
    color: ${props => props.theme.colors["text-primary"].rgba};
    text-align: center;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonsWrapper = styled.div`
    ${flexSmart("space-between center")}
`;

export const CameraWrapper = styled.div`
    margin-top: 20px;
`;

export const CameraSectionWrapper = styled.div`
    width: 100%;
`;

export const PageLayoutWrapper = styled.div`
    border: 2px solid ${props => props.theme.colors["primary"].rgba};
    border-radius: ${BORDER_RADIUS}px;
    padding: 5px;
    gap: 5px;
    width: 230px;
`;

export const PageLayout = styled.div`
    ${flexSmart("space-between center")}
`;

export const PageButtonsWrapper = styled.div`
    ${flexSmart("end center")}
    gap: 18px;
`;

export const StyledButton = styled.div<{backgroundColor?: string, width?: string, color?: string, disabled?: boolean}>`
    ${flexSmart("center center")}

    width: ${props => props.width ?? "140px"};
    height: 25px;
    padding: 12px 5px 12px 0px;
    font-size: 15px;
    font-weight: 400;
    gap: 5px;

    background-color: ${props => props.backgroundColor ?? props.theme.colors["primary"].rgba};

    color: ${props => props.color ?? props.theme.colors["text-secondary"].rgba};

    ${props => props.disabled ? "opacity: 0.5" : ""};

    border: none;
    border-radius: ${BORDER_RADIUS}px;

    :hover {
        cursor: ${props => props.disabled ? 'none' : 'pointer'};
        // cursor: pointer;
    }
`;

export const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%
`;

export const PagerWrapper = styled.div`
    width: 100%;
    ${flexSmart("center center")}
    padding-bottom: 18px;
    padding-top: 18px;
`;

export const VideoboardsWrap = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 18px;
`;

export const Videoboards = styled.div<{rowCount?: number, columnCount?: number}>`
    display: grid;
    grid-template-columns: ${props => props.columnCount === 2 ? `${SS_WIDTH}px ${SS_WIDTH}px` : `${SS_WIDTH}px`};
    grid-template-rows: ${props => props.rowCount === 2 ? `${SS_HEIGHT}px ${SS_HEIGHT}px` : `${SS_HEIGHT}px`};
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 18px;
`;

export const SnapshotWrap = styled.div`
    flex: 1 1 30%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`;

export const SnapshotPlaceholder = styled.div<{hovering?: boolean}>`
    ${flexSmart("center center")}
    background-color: rgba(117, 117, 117, 0.1);
    height: ${SS_HEIGHT}px;
    width: ${SS_WIDTH}px;
    border-radius: ${BORDER_RADIUS}px;

    ${props => props.hovering ? `background-color: rgba(4, 149, 101, 0.09);` : ``}
    
    :hover{
        background-color: rgba(4, 149, 101, 0.09);
    }
`;

export const PageLayoutText = styled.div`
    color: ${props => props.theme.colors["text-primary"].rgba};
    padding-left: 5px;
`;

export const PageLayoutOptions = styled.div`
    gap: 3px;
    display: flex;
    height: 35px;
    justify-content: center;
    align-items: center;
`;

export const PageLayoutSquare = styled.div<{selected?: boolean}>`
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.selected ? props.theme.colors["primary"].rgba : props.theme.colors["bg-primary"].rgba};
    border-radius: 8px;
    :hover {
        cursor: pointer;
    }
`;

export const PageLayoutColumn = styled.div<{selected?: boolean}>`
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    background-color: ${props => props.selected ? props.theme.colors["primary"].rgba : props.theme.colors["bg-primary"].rgba};
    border-radius: 8px;
    :hover {
        cursor: pointer;
    }
`;

export const PageLayoutGrid = styled.div<{selected?: boolean}>`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.selected ? props.theme.colors["primary"].rgba : props.theme.colors["bg-primary"].rgba};
    border-radius: 8px;
    :hover {
        cursor: pointer;
    }
`;

export const PageLayoutGridWrapper = styled.div`
    width: 28px;
    height: 28px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 2.5px;
    align-items: center;
`;

export const Square = styled.div<{width?: string, height?: string, selected?: boolean}>`
    width: ${props => props.width ?? "25px"};
    height: ${props => props.height ?? "25px"};
    border: 1px solid ${props => props.selected ? props.theme.colors["text-secondary"].rgba : props.theme.colors["text-primary"].rgba};
    border-radius: 3px;
`;

export const StyledWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 82px);

    ${flexSmart("center center")}
`;

export const UnavailableWrapper = styled.div`
    min-height: calc(100vh - 122px);
    width: 100%;
    margin: 20px;
    background-color: rgba(4,149,101,0.09);
    border-radius: 12px;

    ${flexSmart("center center")}
    flex-direction: column;
`;

export const EditorText = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    color: ${props => props.theme.colors["primary"].rgba};
`;

export const NoticeText = styled.div`
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 10px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const StyledReturnButton = styled.div`
    ${flexSmart("center center")}

    width: 200px;
    height: 60px;
    font-size: 16px;
    font-weight: 400;

    background-color: rgba(4,149,101,0.15);

    color: ${props => props.theme.colors["primary"].rgba};

    border: none;
    border-radius: 20px;

    :hover{
        cursor: pointer;
    }
`;