// libraries
import * as React from "react";

export type ISaveVideoboardModalContext = {
    showModal: boolean;
    modalContent: React.ReactNode | null;
    setShowModal: (newVal: boolean) => void;
    setModalContent: (newVal: React.ReactNode) => void;
};

// ui state
export const SaveVideoboardModalContext = React.createContext<ISaveVideoboardModalContext>({
    showModal: false,
    modalContent: null,
    setShowModal: (newVal: boolean) => {},
    setModalContent: (newVal: React.ReactNode) => {}
});

// hook to initialize the contexts
export const useSaveVideoboardModalContext = () => {

    // tracks when modal is open/closed
    const [showModal, setShowModal] = React.useState<boolean>(false);

    // tracks which nav route currently selected
    const [modalContent, setModalContent] = React.useState<React.ReactNode | null>(null);

    const SaveVideoboardModalContextInit = {
        
        showModal, 
        setShowModal: (newVal: boolean) => setShowModal(newVal),
        
        modalContent,
        setModalContent: (newVal: React.ReactNode) => setModalContent(newVal),
    };

    return SaveVideoboardModalContextInit;
}

export default SaveVideoboardModalContext;