// libraries
import React, { Fragment } from "react";
// hooks & context
import { ThemeContext } from "../../../theme";
// styles
import * as SC from "./Styled";
// components
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaveConfirmationModal from "../modal/save-videoboard-confirmation-modal/SaveConfirmationModal";
import SaveVideoboardModalContext from "~/contexts/SaveVideoboardModalContext";

export type IProps = {
    title?: string;
    callback?: () => void;
    isSaving?: boolean;
};

export const SaveVideoboardButton: React.FC<IProps> = (props) => {

    const { title, callback, isSaving } = props;
    const theme: any = React.useContext(ThemeContext);
    const saveVideoboardModalContext: any = React.useContext(SaveVideoboardModalContext);

    const saveClickHandler = () => {

        if(!title) {
            saveVideoboardModalContext.setModalContent(
                <SaveConfirmationModal callback={callback && callback} doneCallback={() => saveVideoboardModalContext.setShowModal(false)} />
            );
            saveVideoboardModalContext.setShowModal(true);
        }
        else if(callback){
            callback();
        }

    }

    return (
        <div>
            {
                <SC.StyledButton isSaving={isSaving} onClick={(event: any) => { 
                    if(!isSaving) saveClickHandler(); }}>
            
                    <Fragment>
                        <FontAwesomeIcon
                            className="fa-fw"
                            icon={faSave}
                            size={"1x"}
                            color={theme.colors["text-secondary"].rgba}
                        />
                        {"Save"}
                    </Fragment>

                </SC.StyledButton>
            }
        </div>
        
    );
};

export default SaveVideoboardButton;