// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from "styled-components";
import { faCircleExclamation, faTrash } from "@fortawesome/pro-regular-svg-icons";
// components

export type IProps = {
    doneCallback: () => void;
};

export const DeleteErrorModal: React.FC<IProps> = (props) => {

    const {
        doneCallback
    } = props;
    
    return (
        <SC.StyledModal>

            <FontAwesomeIcon
                className="fa-fw"
                icon={faCircleExclamation}
                size={"5x"}
                color={"#FF3B30"}
            />

            <SC.TextSection>
                <SC.BoldText>There was a problem while deleting the videoboard.</SC.BoldText>
                <SC.Text>Please try again.</SC.Text>
            </SC.TextSection>

            <SC.ButtonSection>
                <SC.StyledButton cancel={true} onClick={doneCallback}>
                    {"Close"}
                </SC.StyledButton>
            </SC.ButtonSection>

        </SC.StyledModal>
    )
}

export default DeleteErrorModal;