// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from "styled-components";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
// components
import { deleteVideoboard as deleteVideoboardItem } from "~/store/algo-api/slices/videoboards";

export type IProps = {
    id: number;
    doneCallback: () => void;
};

export const DeleteConfirmationModal: React.FC<IProps> = (props) => {

    const {
        id,
        doneCallback
    } = props;

    const dispatch = useDispatch();
    
    const [ deleteVideoboard, setDeleteVideoboard ] = React.useState<boolean>(false);
    const userStore = useSelector( (state: any) => state.users);
    React.useEffect(() => {
        if (userStore.profile){
            let profile: AAUserAuthorizationProfile = new AAUserAuthorizationProfile(userStore.profile);
            setDeleteVideoboard(profile.hasPrivilege("DeleteVideoboard"));
        }
    }, [userStore.profile]);
    
    return (
        <SC.StyledModal>

            <FontAwesomeIcon
                className="fa-fw"
                icon={faTrash}
                size={"5x"}
                color={"#FF3B30"}
            />

            <SC.TextSection>
                <SC.BoldText>Are you sure you want to delete this videoboard?</SC.BoldText>
                <SC.Text>You can't undo this action.</SC.Text>
            </SC.TextSection>

            <SC.ButtonSection>
                <SC.StyledButton cancel={true} onClick={doneCallback}>
                    {"Cancel"}
                </SC.StyledButton>

                <SC.StyledButton disabled={!deleteVideoboard} onClick={() => {
                    dispatch(deleteVideoboardItem(id));
                    doneCallback();}}>
                    <Fragment>
                        {"Delete Videoboard"}
                    </Fragment>
                </SC.StyledButton>
            </SC.ButtonSection>

        </SC.StyledModal>
    )
}

export default DeleteConfirmationModal;