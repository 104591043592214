// libraries
import * as React from "react";
import { INavLink, INavSection } from "~/navigation/navigation-map";
// styles
import * as SC from "./Styled";
// context
import SiteNavContext, { isNavLinkSelected } from "~/navigation/SiteNavContext";
import { useDispatch, useSelector } from "react-redux";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
import { useAuth } from "react-oidc-context";
import { getProfile } from "~/store/algo-api/slices/users";
// constants

export type IProps = {
    navSection: INavSection;
};

export const NavBarSection: React.FC<IProps> = (props) => {

    // BREVITY
    const { navSection } = props;

    const sectionLinks: INavLink[] = navSection.links || [];

    // CONTEXT 
    const navContext: any = React.useContext(SiteNavContext);
    const dispatch = useDispatch();
    const userStore = useSelector( (state: any) => state.users);
    const [ viewVideoboard, setViewVideoboard ] = React.useState<boolean>(false);
    const auth = useAuth();

    React.useEffect(() => {
        dispatch(getProfile() as any);
    }, [auth.isAuthenticated]);

    React.useEffect(() => {
        if (userStore.profile){
            let profile: AAUserAuthorizationProfile = new AAUserAuthorizationProfile(userStore.profile);
            setViewVideoboard(profile.hasPrivilege("ViewVideoboard"));
        }
    }, [userStore.profile]);

    return (
        <SC.StyledNavBarSection>

                {/* Render each of the section nav links, if any exist */}
                <SC.SectionLinks
                    panelOpen={navContext.panelOpen}
                >
                    {sectionLinks && sectionLinks.map(
                        (link: any) => {
                            if(link.name === "Videoboards" && !viewVideoboard) return;
                            let isSelected: boolean = isNavLinkSelected(
                                navContext.selectedSiteLink, navSection.id, link.id
                            );
                            return (
                                <SC.NavLink
                                    key={link.name + link.id} index={link.id}
                                    isSelected={isSelected} panelOpen={navContext.panelOpen}
                                    href={link.link || undefined}
                                    target={link.link ? "_blank" : undefined}
                                    onClick={
                                        () => { 
                                            if (link.route) {
                                                navContext.setSelectedSiteLink([navSection.id, link.id]);
                                                navContext.setSelectedRoute(link.route);
                                            }
                                        }
                                    }
                                >
                                    <SC.LinkIcon isSelected={isSelected}>
                                        {link.iconComp 
                                            ? link.iconComp
                                            : null
                                        }
                                    </SC.LinkIcon>
                                    {   navContext.panelOpen &&
                                        <SC.LinkName>
                                            {link.name}
                                        </SC.LinkName>
                                    }
                                </SC.NavLink>
                            )
                        }
                    )}
                </SC.SectionLinks>
            
        </SC.StyledNavBarSection>
    )
};

export default NavBarSection;