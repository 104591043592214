// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { IATCameraDto, IATVideoItemDto, IATVideoPageDto } from "@algo/network-manager/models/v4";
// components
import CameraInfoOverlay from "../../camera-info-overlay/CameraInfoOverlay";
import { VideoJs } from "../local-videoJS";
// constants
import { fairplay_certificateUrl } from "~/constants";

const STANDARD_VJS_CONFIG = {
    autoplay: true,
    controls: false,
};

declare var __WIDEVINE_LICENSE_URL__: string;
declare var __FAIRPLAY_LICENSE_URL__: string;

export type IProps = {
    page?: IATVideoPageDto | null;
    showRouteOverlay?: boolean;
    showCityOverlay?: boolean;
    lockOverlays?: boolean;
    fullscreen?: boolean;
    isVideoboard?: boolean;
    updatePlayerMap: (key: string, player: any) => void;
};

export const VideoGrid: React.FC<IProps> = (props) => {

    const {
        page, showCityOverlay, showRouteOverlay, lockOverlays, fullscreen, isVideoboard
    } = props;

    // Helper function to detect browser and platform
    const isSafari = (): boolean => {
        const userAgent = navigator.userAgent.toLowerCase();
        return /safari/.test(userAgent) && !/chrome/.test(userAgent); // Only Safari, not Chrome
    };

    const isMacOrIOS = (): boolean => {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('mac') || userAgent.includes('iphone') || userAgent.includes('ipad');
    };

    const isWidevineSupported = (): boolean => {
        const userAgent = navigator.userAgent.toLowerCase();
        return /chrome|edge|firefox/.test(userAgent);
    };

    // Determine the configuration based on platform
    const getStreamConfig = (item: IATVideoItemDto) => {
        let config: any = null;

        const isHLS = isSafari() && isMacOrIOS();
        const isDASH = isWidevineSupported();
    
        if (isHLS && item?.camera?.playbackUrls?.hls) {
            const source = {
                src: item.camera.playbackUrls.hls,  // HLS URL
                type: 'application/x-mpegURL',
                keySystems: {
                    'com.apple.fps.1_0': {
                        certificateUri: fairplay_certificateUrl,
                        licenseUri: __FAIRPLAY_LICENSE_URL__,
                        options: {
                            persistentState: 'required'
                        } 
                  },
                }
            };

            config = {
                ...STANDARD_VJS_CONFIG,
                poster: item?.camera?.snapshotImageUrl,
                sources: [source],
                html5: {
                    hls: {
                        withCredentials: true,
                    },
                  }
            };
        } else if (isDASH && item?.camera?.playbackUrls?.dash) {
            const source = {
                src: item.camera.playbackUrls.dash,  // DASH URL
                type: 'application/dash+xml',
                keySystems: {
                    'com.widevine.alpha': __WIDEVINE_LICENSE_URL__
                } 
            };

            config = {
                ...STANDARD_VJS_CONFIG,
                poster: item?.camera?.snapshotImageUrl,
                sources: [source]
            };
        }
    
        // Return source or null if no valid source
        return config || null;
    }; 

    // Handle cases where no valid stream is found
    if (!page) return null;

    const oneColumn: boolean = isVideoboard ? page?.columnCount === 1 : page?.items.length && page?.items.length > 1 ? false : true;
    const twoVideos: boolean = page?.items.length === 2 ? true : false;

    return (
        <SC.VideoGrid oneColumn={oneColumn} fullscreen={fullscreen} twoVideos={twoVideos}>
            { page && page.items.map(
                (item: IATVideoItemDto, mapIndex: number) => {
                    const config = getStreamConfig(item);

                    const camera: IATCameraDto | null = item.camera;

                    return (
                        <SC.VideoItem key={item.id || mapIndex} fullscreen={fullscreen} twoVideos={twoVideos}>
                            { camera &&
                                <VideoJs
                                    style={{height: "100%", width: "100%"}}
                                    rounded={false} playButton={true}
                                    config={config} videoClassNames=""
                                    videoDimensions={{width: `100%`, height: `100%`}}
                                    playerCallback={
                                        (player: any) => {
                                            props.updatePlayerMap(`${item.id}${mapIndex}`, player);
                                        }
                                    }
                                />
                            }

                            { !isVideoboard && camera &&
                                <SC.TitleItem lockOverlays={lockOverlays}>
                                    <CameraInfoOverlay camera={camera} 
                                        showRouteOverlay={showRouteOverlay} showCityOverlay={showCityOverlay} />
                                </SC.TitleItem>
                            }

                        </SC.VideoItem>
                    );
            })}
        </SC.VideoGrid>
    );
};

export default VideoGrid;
