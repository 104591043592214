// libraries
import { useEffect, useRef } from "react";
import videojs from "video.js";
import "videojs-contrib-eme";

export const useInitializePlayer = (
    d_config: any,
    playerCallback?: (player: any) => void
): React.RefObject<HTMLVideoElement> => {

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = useRef<any | null>(null);

    useEffect(() => {
        if (!playerRef.current && videoRef.current) {
            const player: any = videojs(videoRef.current, d_config, () => { });
            player.eme();
            playerRef.current = player;
        }

        if (playerCallback) {
            playerCallback(playerRef.current);
        }

        return () => {
            if (playerRef.current && !videoRef.current) {
              playerRef.current.dispose();
              playerRef.current = null; 
            }
          };
    }, [d_config, playerCallback]);

    return videoRef;
};
