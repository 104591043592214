// libraries
import React, { useEffect, useState } from "react";
// hooks & context
import { ThemeContext } from "../../../theme";
// styles
import * as SC from "./Styled";
// components

export type IProps = {
    pageOnSecond?: number;
    onTimeChange?:(val: number)=> void;
    callback?: () => void;
};

export const TransitionTimer: React.FC<IProps> = (props) => {

    const {pageOnSecond = 60, onTimeChange} = props;

    const [selectedTime, setSelectedTime] = useState(pageOnSecond); 

    // List of times as numbers; 0 represents "Off"
    const times = [0, 15, 30, 45, 60];
  
    useEffect(() => {
      setSelectedTime(pageOnSecond);
    }, [pageOnSecond]);
  
    const handleSelection = (time: number) => {
      setSelectedTime(time);
      onTimeChange?.(time); // Update the parent component with the selected time
    };

    return (
        <SC.TimerContainer>
            <SC.Timer>
                <SC.TimerLabel>Transition Timer</SC.TimerLabel>
                <SC.TimerOptions>
                    {times.map((time) => (
                        <SC.TimerButton
                            key={time}
                            selected={selectedTime === time}
                            onClick={() => handleSelection(time)}
                        >
                            <SC.TimerButtonLabel selected={selectedTime === time}>{ time === 0 ? 'Off' : `${time}s` }</SC.TimerButtonLabel>
                        </SC.TimerButton>
                    ))}
                </SC.TimerOptions>
            </SC.Timer>
        </SC.TimerContainer>
    );
};

export default TransitionTimer;