// libraries
import * as React from "react";
import { nuon } from "@caps-mobile/common-lib";
// types & models
import {IATCamera, IATVideoBoard, IATVideoBoardDto } from "@algo/network-manager/models/v4";
// styles
import * as SC from "./Styled";
// components
import ImgWithDefault from "../image-with-default/ImgWithDefault";
// resources
import noPreviewImg from "~/resources/ui/graphics/no-camera-preview-url-provided-videoboard.png";
import playBtn from "~/resources/ui/graphics/play-button.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPenToSquare, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
import { useBreakpoint } from "~/library/useBreakpoint";
import DeleteVideoboardModalContext from "~/contexts/DeleteVideoboardModalContext";
import DeleteConfirmationModal from "../modal/delete-confirmation-modal/DeleteConfirmationModal";
import SiteNavContext from "~/navigation/SiteNavContext";

export type IProps = {
    videoboard: IATVideoBoard | IATVideoBoardDto;
    camera: IATCamera | undefined | null;
    height?: string;
    width?: string;
    showPlayButton?: boolean;
    clickCallback?: () => void;
};

export const Snapshot: React.FC<IProps> = (props) => {

    let {  
        height = "100%", width = "100%", 
        videoboard, camera, showPlayButton, clickCallback 
    } = props;
    
    const imageUrl = camera?.snapshotImageUrl || noPreviewImg;

    const hasClickCallback: boolean = nuon(clickCallback);

    const [ viewSettings, setViewSettings ] = React.useState<boolean>(false);

    const navContext: any = React.useContext(SiteNavContext);

    const { lg: isLargeMax } = useBreakpoint();
    
    const userStore = useSelector( (state: any) => state.users);
    const [ editVideoboard, setEditVideoboard ] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (userStore.profile){
            let profile: AAUserAuthorizationProfile = new AAUserAuthorizationProfile(userStore.profile);
            setEditVideoboard(profile.hasPrivilege("EditVideoboard"));
        }
    }, [userStore.profile]);
    
    const deleteVideoboardModalContext: any = React.useContext(DeleteVideoboardModalContext);

    const deleteClickHandler = () => {

        deleteVideoboardModalContext.setModalContent(
            <DeleteConfirmationModal id={videoboard.id} doneCallback={() => deleteVideoboardModalContext.setShowModal(false)} />
        );
        deleteVideoboardModalContext.setShowModal(true);
    }

    return (
        <SC.SnapshotWrapper isClickable={hasClickCallback}
            onClick={() => clickCallback && clickCallback()}
        >

            <ImgWithDefault 
                src={imageUrl} fallbackSrc={noPreviewImg} 
                width={width} height={height} isVideoboard={true} />

            <SC.TopRow>

                <SC.Overlay>

                    <SC.Primary>{videoboard.name}</SC.Primary>

                </SC.Overlay>

            </SC.TopRow>

            {   showPlayButton &&
                <SC.FloatingPlayBtn>
                    <ImgWithDefault src={playBtn} width={"52px"} height={"52px"} isVideoboard={true} />
                </SC.FloatingPlayBtn>
            }

            <SC.BottomRow>

                { !viewSettings &&
                    <SC.Overlay bottom={true} onClick={(event: any) => { event.stopPropagation(); setViewSettings(true); }}>

                        <SC.Primary>
                            <FontAwesomeIcon
                                className="fa-fw"
                                icon={faGear}
                                size={"xl"}
                                color={"#FFFFFF"}
                            />
                        </SC.Primary>

                    </SC.Overlay>
                }

                { viewSettings &&
                    <SC.SettingsWrapper>
                        <SC.Overlay bottom={true} onClick={(event: any) => { 
                            event.stopPropagation();
                            deleteClickHandler();  }}>

                            <SC.Primary>
                                <FontAwesomeIcon
                                    className="fa-fw"
                                    icon={faTrash}
                                    size={"xl"}
                                    color={"#FF3B30"}
                                />
                            </SC.Primary>

                        </SC.Overlay>

                        { !isLargeMax && editVideoboard &&
                            <SC.Overlay bottom={true} onClick={(event: any) => { 
                                event.stopPropagation(); 
                                navContext.setSelectedSiteLink([1,0]); 
                                navContext.setSelectedRoute("/videoboards/editor/" + videoboard.id); }}>

                                <SC.Primary>
                                    <FontAwesomeIcon
                                        className="fa-fw"
                                        icon={faPenToSquare}
                                        size={"xl"}
                                        color={"#FFFFFF"}
                                    />
                                </SC.Primary>

                            </SC.Overlay>
                        }

                    </SC.SettingsWrapper>
                }

            </SC.BottomRow>

        </SC.SnapshotWrapper>
    )
};

export default Snapshot;