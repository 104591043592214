// libraries
import { getAll as getAllVideoboards } from "~/store/algo-api/slices/videoboards";
// hooks & context
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "@algo/hooks";
import { minuteToMilli } from "@caps-mobile/date-time";

const REFRESH_INTERVAL = minuteToMilli(1); // 1 minute in milliseconds

export const useVideoboardData = (searchText?: string) => {
    const dispatch = useDispatch();

    const videoboardStore = useSelector((state: any) => state.videoboard);
    const checksum = videoboardStore.lastChecksum;
    const prevChecksum = usePrevious(checksum);

    const prevSearchText = usePrevious(searchText);

    const headCall = videoboardStore.headOnly;

    useEffect(() => {
        const fetchData = () => {
            if (searchText !== prevSearchText || (headCall && checksum !== prevChecksum)) {
                dispatch(getAllVideoboards(false, searchText)); // GET request for new data
            }
        };

        fetchData();

        // Set up interval for checksum checking
        const timeoutId = setInterval(() => {
            dispatch(getAllVideoboards(true, searchText)); // HEAD request
        }, REFRESH_INTERVAL);

        return () => clearInterval(timeoutId); // Cleanup on unmount
    }, [checksum, searchText]); // Dependencies
};