// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants
import { BORDER_RADIUS } from "~/constants";

export const TimerContainer = styled.div`
  border: 2px solid ${props => props.theme.colors["primary"].rgba};
  border-radius: ${BORDER_RADIUS}px;
  padding: 5px;
`;

export const Timer = styled.div`
    ${flexSmart("space-between center")}
`;

export const TimerLabel = styled.div`
  width: 120px;
  color: ${props => props.theme.colors["text-primary"].rgba};
  padding-left: 5px;
`;

export const TimerOptions = styled.div`
    gap: 3px;
    display: flex;
    height: 35px;
    justify-content: center;
    align-items: center;
`;

export const TimerButton = styled.button<{selected?: boolean}>` 
  padding: 8px;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  background-color: ${props => props.selected ? props.theme.colors["primary"].rgba : props.theme.colors["bg-primary"].rgba};
  border-radius: 8px;
`;

export const TimerButtonLabel = styled.span<{selected?: boolean}>`
  font-size: 15px;
  font-weight: 300;
  color: ${props => props.selected ? props.theme.colors["text-secondary"].rgba : props.theme.colors["text-primary"].rgba};
`;