// libraries
import * as React from "react";
// hooks & context
import DetailModalContext from "~/contexts/DetailModalContext";
import { SiteNavContext } from "~/navigation/SiteNavContext";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";
// styles
import * as SC from "./Styled";
// components
import FullPageModal from "../views/modal/FullPageModal";
import NavPanel from "~/navigation/nav-panel/NavPanel";
import RoutesWrapper from "~/navigation/routes-wrapper/RoutesWrapper";
import NavBar from "~/navigation/nav-bar/NavBar";
import { useBreakpoint } from "../../library/useBreakpoint";
import { useAuth } from "react-oidc-context";
import NoCameraModalContext from "~/contexts/NoCameraModalContext";
import DeleteVideoboardModalContext from "~/contexts/DeleteVideoboardModalContext";
import DeleteVideoboardErrorModalContext from "~/contexts/DeleteVideoboardErrorModalContext";
import SaveVideoboardErrorModalContext from "~/contexts/SaveVideoboardErrorModalContext";
import SaveVideoboardModalContext from "~/contexts/SaveVideoboardModalContext";


export type IProps = {
    //
};

export const AppContent: React.FC<IProps> = (props) => {

    const detailModal: any = React.useContext(DetailModalContext);
    const videoboardModal: any = React.useContext(VideoboardModalContext);
    const noCameraModal: any = React.useContext(NoCameraModalContext);
    const deleteVideoboardModal: any = React.useContext(DeleteVideoboardModalContext);
    const deleteVideoboardErrorModal: any = React.useContext(DeleteVideoboardErrorModalContext);
    const saveVideoboardErrorModal: any = React.useContext(SaveVideoboardErrorModalContext);
    const saveVideoboardModal: any = React.useContext(SaveVideoboardModalContext);
    const navContext: any = React.useContext(SiteNavContext);

    //check if there is an access token and if so send message to service worker
    const serviceWorker = navigator?.serviceWorker?.controller;
    const auth = useAuth();
    if(auth.user?.access_token){
        serviceWorker?.postMessage({
            accessToken: auth.user?.access_token
        });
    }
    else{
        serviceWorker?.postMessage({
            accessToken: ''
        });
    }

    const { sm: isSmallMax } = useBreakpoint();

    return (

        <SC.StyledApp>
                                
            <SC.StyledNavPlusContent isSmallMax={isSmallMax}>

                {   detailModal.showModal &&
                    <FullPageModal 
                        closeCallback={() => detailModal.setShowModal(false)}
                    >
                        {detailModal.modalContent}
                    </FullPageModal>
                }

                {   videoboardModal.showModal &&
                    <FullPageModal
                        closeCallback={() => videoboardModal.setShowModal(false)}
                    >
                        {videoboardModal.modalContent}
                    </FullPageModal>
                }

                {   noCameraModal.showModal &&
                    <FullPageModal
                        closeCallback={() => noCameraModal.setShowModal(false)}
                    >
                        {noCameraModal.modalContent}
                    </FullPageModal>
                }

                {   deleteVideoboardModal.showModal &&
                    <FullPageModal
                        closeCallback={() => deleteVideoboardModal.setShowModal(false)}
                    >
                        {deleteVideoboardModal.modalContent}
                    </FullPageModal>
                }  

                {   deleteVideoboardErrorModal.showModal &&
                    <FullPageModal
                        closeCallback={() => deleteVideoboardErrorModal.setShowModal(false)}
                    >
                        {deleteVideoboardErrorModal.modalContent}
                    </FullPageModal>
                }  

                {   saveVideoboardErrorModal.showModal &&
                    <FullPageModal
                        closeCallback={() => saveVideoboardErrorModal.setShowModal(false)}
                    >
                        {saveVideoboardErrorModal.modalContent}
                    </FullPageModal>
                } 

                {   saveVideoboardModal.showModal &&
                    <FullPageModal
                        closeCallback={() => saveVideoboardModal.setShowModal(false)}
                    >
                        {saveVideoboardModal.modalContent}
                    </FullPageModal>
                }  

                {   !isSmallMax && <NavPanel /> }

                <SC.StyledContent panelOpen={navContext.panelOpen} isSmallMax={isSmallMax}>
                    <RoutesWrapper />
                </SC.StyledContent>

                {   isSmallMax && <NavBar /> }

            </SC.StyledNavPlusContent>                    

        </SC.StyledApp>
        
    );
};

export default AppContent;
