// libraries
import * as React from "react";
import { SHOW_COUNT } from "~/constants";
// styled
import * as SC from "./Styled";
import { EAlgoApiObjectType } from "~/interfaces";
// component
import DetailCardsSection from "./section-types/DetailCardsSection";
import SnapshotSection from "./section-types/SnapshotSection";
import VideoboardSnapshotSection from "./section-types/VideoboardSnapshotSection";

export enum EDetailDisplayType {
    "detail" = "detail",
    "snapshot" = "snapshot",
    "videoboard-snapshot" = "videoboard-snapshot"
}

export type IProps = {
    showAll?: boolean;
    showCount?: number;
    showChevron?: boolean;
    isCollapseable?: boolean;
    dataType: EAlgoApiObjectType;
    displayType?: EDetailDisplayType;
    dataObjects?: any[];
};

export const DetailsSection: React.FC<IProps> = (props) => {

    const { 
        dataType, displayType = "detail", dataObjects = [], 
        showAll, showChevron, isCollapseable
    } = props;

    const [ showCount, setShowCount ] = React.useState<number>(props.showCount || SHOW_COUNT);
    const [ showScaler, setShowScaler ] = React.useState<number>(1);

    if (!dataObjects || dataObjects.length === 0) return null;

    const sliceMax: number = showCount * showScaler;
    const showing: number = (showAll) 
        ? dataObjects.length
        : Math.min(sliceMax, dataObjects.length);
    
    const reducedObjectsList: any = dataType === EAlgoApiObjectType.camera ? dataObjects :
        dataObjects?.slice(0, showing);

    return (
        <SC.StyledDetailsSection>
            
            { displayType === EDetailDisplayType.snapshot
                ? <SnapshotSection objectList={reducedObjectsList}/>
                : displayType === EDetailDisplayType["videoboard-snapshot"]
                ? <VideoboardSnapshotSection objectList={reducedObjectsList}/>
                : <DetailCardsSection 
                    objectsList={reducedObjectsList} dataType={dataType} 
                    showChevron={showChevron} isCollapseable={isCollapseable} />
            }

            { (!showAll && dataObjects.length > SHOW_COUNT) &&
                <SC.ShowMore 
                    onClick={
                        () => {
                            (showing >= dataObjects.length)
                                ? setShowScaler(1)
                                : setShowScaler(showScaler => showScaler + 1);
                        }
                    }
                >
                    <SC.MoreLessText>
                        {`${ (showing >= dataObjects.length) ? "Show Less" : "Show More"}`}
                    </SC.MoreLessText>
                </SC.ShowMore>
            }

        </SC.StyledDetailsSection>
    );

};

export default DetailsSection;