// libraries
import { IATVideoBoardDto } from "@algo/network-manager/models/v4";
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { Videoboard } from "~/components/views/videoboard/Videoboard";

export type IProps = {
    videoboard: IATVideoBoardDto;
    isVideoboard?: boolean;
    doneCallback: () => void;
};

export const VideoboardModal: React.FC<IProps> = (props) => {

    const {
        videoboard, isVideoboard, doneCallback
    } = props;

    return (
        <SC.StyledVideoboardModal>

            <Videoboard videoboard={videoboard} isVideoboard={isVideoboard} streamTimeout={videoboard.pageOnSeconds} closeCallback={doneCallback}/>

        </SC.StyledVideoboardModal>
    )
}

export default VideoboardModal;